import { t } from "i18next";
import { GoArrowRight } from "react-icons/go";
import CircleButton from "widgets/shared/buttons/circle-button";

// Props
type DashboardCardProps = {
  title?: string;
  extra?: string;
  bgColor?:
    | "white"
    | "50"
    | "100"
    | "200"
    | "300"
    | "400"
    | "500"
    | "600"
    | "700"
    | "800"
    | "900";
  textColor?: "primary" | "secondary";
  children?: React.ReactNode;
  topRight?: React.ReactNode;
  onClick: () => void;
};

const SupportCard = ({
  title,
  extra,
  textColor = "primary",
  bgColor = "white",
  children,
  topRight,
  onClick,
}: DashboardCardProps) => {
  return (
    <div
      onClick={onClick}
      className={`!z-5 relative h-48 w-72 rounded-[15px] ${`bg-brand-${bgColor}`} ${`text-${textColor}`} p-4 md:p-4 md:px-6 ${extra}`}
    >
      <div className="mb-2 flex w-full flex-col">
        <div className="flex w-full flex-row justify-between gap-2">
          <div className="flex flex-col truncate">
            <div className={`heading-lg ${textColor}`}>{title}</div>
          </div>

          <div className="">{topRight}</div>
        </div>
      </div>
      <div className="flex-grow"></div>
      <div className="heading-4xl mt-4 text-secondary">{""}</div>
      <div className="absolute bottom-4 right-2">
        <CircleButton
          label={t("button.action", { context: "manage" })}
          size="xs"
          variant="ghost"
          color={textColor}
          icon={GoArrowRight}
          iconPosition="right"
        />
      </div>
    </div>
  );
};

export default SupportCard;
