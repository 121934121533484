// Imports
import { createColumnHelper } from "@tanstack/react-table";
import { t } from "i18next";

// Define the structure of the JdTableModel
export class UsageTableModel {
  timestamp: string;
  raw: any;
  reverse?: any;
}
const defineUsageTablecolumns = (isMulti: boolean = false) => {
  // Initialize the translation hook and

  // create a column helper for the JdTableModel
  const columnHelper = createColumnHelper<UsageTableModel>();

  // Define the columns for the JdTable
  const usageTablecolumns = [
    // Description column
    columnHelper.accessor("timestamp", {
      enableSorting: true,
      header: () => <span className="">{t("table.header.timestamp")}</span>,
      cell: (info) => <span className="">{info.getValue()}</span>,
      meta: { rowClick: true },
    }),

    // Status column
    columnHelper.accessor("raw", {
      enableSorting: false,
      header: () => <span className="">{t("table.header.usage")}</span>,
      cell: (info) => <span className="">{info.getValue()}</span>,
      meta: { rowClick: true },
    }),
  ];

  // Conditionally add another accessor if isMulti is true
  if (isMulti) {
    usageTablecolumns.push(
      columnHelper.accessor("reverse", {
        enableSorting: false,
        header: () => <span className="">{t("table.header.reverse")}</span>,
        cell: (info) => <span className="">{info.getValue()}</span>,
        meta: { rowClick: true },
      })
    );
  }

  return usageTablecolumns;
};

export default defineUsageTablecolumns;
