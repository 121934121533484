import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalCloseButton,
} from "@chakra-ui/modal";
import { PortalManager } from "@chakra-ui/portal";
import { jobUpdateState } from "api/job";
import { JobStatusDropdown } from "models/dropdowns/jobStatus";
import { JobStatusIcon } from "providers/icons";
import { notifySimple } from "providers/toast";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setLoading } from "store/appSlice";
import GenericButton from "widgets/shared/buttons/generic";
import Radio from "widgets/shared/inputs/radio";

export type JOB_STATUS = "pending" | "completed" | "failed";

const JobStatusDialog = (props: {
  isOpen: boolean;
  id: string;
  status: JOB_STATUS;
  onClose: () => void;
  onSuccess: () => void;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [jobStatus, setJobStatus] = useState<JOB_STATUS>("pending");

  // Function for linking an user
  const doDqEdit = async () => {
    dispatch(setLoading(true));

    const { status } = await jobUpdateState({
      id: props.id,
      state: jobStatus,
    });
    dispatch(setLoading(false));
    if (status === 200) {
      props.onSuccess();
      notifySimple("Status updated", "success");
    } else {
      notifySimple("Failed to update", "error");
    }
  };

  useEffect(() => {
    if (props.id && props.status) {
      setJobStatus(props.status);
    }
  }, [props.status]);

  return (
    <>
      <PortalManager zIndex={2000}>
        <Modal isCentered isOpen={props.isOpen} onClose={props.onClose}>
          <ModalOverlay className="bg-[#000] !opacity-30" />
          <ModalContent className="top-[22vh] !m-auto !w-max min-w-96 !max-w-[40%] rounded-md bg-white px-6 md:top-[12vh]">
            <ModalHeader className="heading-md  py-4 text-primary dark:text-darkPrimary">
              {t("dialog.job.status.title")}
            </ModalHeader>
            <ModalCloseButton className="absolute right-[20px] top-[20px] z-10 text-primary dark:text-darkPrimary" />
            <ModalBody className="py-2 text-txt-md text-tertiary">
              <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6">
                {JobStatusDropdown &&
                  JobStatusDropdown.map((item, index) => {
                    const { statusIcon } = JobStatusIcon(item.label);
                    return (
                      <div
                        className="flex items-center text-sm"
                        key={`role-${index}`}
                      >
                        <Radio
                          labelKey={item.label}
                          checked={jobStatus === item.value}
                          onChange={() =>
                            setJobStatus(item.value as JOB_STATUS)
                          }
                        />
                        <div className="flex items-center gap-2">
                          {statusIcon}
                        </div>
                      </div>
                    );
                  })}
              </div>

              <div className="flex gap-2 pb-2 pt-4">
                <GenericButton
                  text={t("button.action.cancel")}
                  onClick={() => props.onClose()}
                  variant="ghost"
                  size="md"
                  extra="w-full"
                />
                <GenericButton
                  text={t("button.action.ok")}
                  onClick={doDqEdit}
                  size="md"
                  extra="w-full"
                />
              </div>
            </ModalBody>
          </ModalContent>
        </Modal>
      </PortalManager>
    </>
  );
};

export default JobStatusDialog;
