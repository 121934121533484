import { gateway } from "./clients/baseClient";
import { ENDPOINTS } from "./endpoints/index";
import { ResponseData } from "./types/api";
import makeApiRequest from "./clients/apiRequest";
import { versionApiPath } from "./common";
import {
  AccountsReqData,
  UserLinkReqBody,
  UserLinkReqData,
  UserPageReqBody,
  UserPageReqData,
} from "./types/user";

export async function userAccounts(): Promise<ResponseData> {
  const data: AccountsReqData = {
    endpoint: `${versionApiPath}/${ENDPOINTS.USER.ACCOUNTS.GET}`,
    body: {},
  };
  return await makeApiRequest(
    {
      url: ``,
      method: "post",
      data: data,
    },
    gateway,
    true
  );
}

export async function userPage(body: UserPageReqBody): Promise<ResponseData> {
  const data: UserPageReqData = {
    endpoint: `${versionApiPath}/${ENDPOINTS.USER.PAGE}`,
    body: body,
  };
  return await makeApiRequest(
    {
      url: ``,
      method: "post",
      data: data,
    },
    gateway,
    false
  );
}

export async function userLink(body: UserLinkReqBody): Promise<ResponseData> {
  const data: UserLinkReqData = {
    endpoint: `${versionApiPath}/${ENDPOINTS.USER.LINK}`,
    body: body,
  };
  return await makeApiRequest(
    {
      url: ``,
      method: "post",
      data: data,
    },
    gateway,
    true
  );
}

export async function userUnlink(email: string): Promise<ResponseData> {
  const data = {
    endpoint: `${versionApiPath}/${ENDPOINTS.USER.UNLINK}`,
    body: { email: email },
  };
  return await makeApiRequest(
    {
      url: ``,
      method: "post",
      data: data,
    },
    gateway,
    true
  );
}

export async function userEdit(body: UserLinkReqBody): Promise<ResponseData> {
  const data: UserLinkReqData = {
    endpoint: `${versionApiPath}/${ENDPOINTS.USER.UPDATE}`,
    body: body,
  };
  return await makeApiRequest(
    {
      url: ``,
      method: "post",
      data: data,
    },
    gateway,
    true
  );
}

export async function deleteAccount(email: string): Promise<ResponseData> {
  const data = {
    endpoint: `${versionApiPath}/${ENDPOINTS.USER.DELETE_ACCOUNT}`,
    body: { email: email },
  };
  return await makeApiRequest(
    {
      url: ``,
      method: "post",
      data: data,
    },
    gateway,
    true
  );
}

export async function userGet(id: string): Promise<ResponseData> {
  const data = {
    endpoint: `${versionApiPath}/${ENDPOINTS.USER.GET.INDEX}`,
    body: { id: id },
  };
  return await makeApiRequest(
    {
      url: ``,
      method: "post",
      data: data,
    },
    gateway,
    true
  );
}

export async function userGetInstaller(
  username: string
): Promise<ResponseData> {
  const data = {
    endpoint: `${versionApiPath}/${ENDPOINTS.USER.GET.INSTALLER}`,
    body: { username: username },
  };
  return await makeApiRequest(
    {
      url: ``,
      method: "post",
      data: data,
    },
    gateway,
    true
  );
}
