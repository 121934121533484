import { Device } from "api/types/device";
import { useEffect, useState } from "react";
import { formatDate, formatDateToZ } from "util/datelib";
import BounceLoader from "react-spinners/ClipLoader";
import { DeviceConfiguration } from "models/devices/configuration";
import { t } from "i18next";
import DeviceCard from "widgets/cards/device";
import { JsonViewer } from "widgets/shared/jsonViewer";
import DateRangePickerField from "widgets/shared/inputs/dateRangePicker";
import ComboBox from "widgets/shared/inputs/combobox";
import { dataAverageTimeSeries, dataRaws, dataUsage } from "api/data";
import { DataRawsReqBody, DataReqBody } from "api/types/data";
import Switch from "widgets/shared/inputs/switch";
import NoDataSvg from "assets/svg/no-data.svg";

type LooseValue = Date | [Date, Date] | null;

interface GeneralProps {
  device?: Device;
  config?: DeviceConfiguration;
}

const Raws: React.FC<GeneralProps> = ({ device, config }) => {
  const [dateRange, setDateRange] = useState<LooseValue>(() => {
    const today = new Date();
    const lastWeek = new Date();
    lastWeek.setDate(today.getDate() - 7);
    return [lastWeek, today];
  });

  const [json, setJson] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [selectedView, setSelectedView] = useState<"raws" | "aggregates">(
    "raws"
  );

  const fetchData = async () => {
    setLoading(true);

    if (dateRange && Array.isArray(dateRange)) {
      const from = formatDateToZ(new Date(dateRange[0]), false); // Start of selected date
      const to = formatDateToZ(new Date(dateRange[1]), true); // End of selected date

      const req: DataRawsReqBody = {
        snr: device?.snr,
        from: from,
        to: to,
      };

      const { data, isLoading, error } = await dataRaws(req);
      setLoading(isLoading);

      if (!error) {
        setJson(data);
      }
    }
  };

  const fetchAggregates = async () => {
    setLoading(true);

    if (dateRange && Array.isArray(dateRange)) {
      const from = formatDateToZ(new Date(dateRange[0]), false); // Start of selected date
      const to = formatDateToZ(new Date(dateRange[1]), true); // End of selected date

      if (config.type === "usage" || config.type === "usage-multi") {
        const req: DataReqBody = {
          snr: device?.snr,
          from: from,
          to: to,
          bucket: "hour",
        };

        const { data, isLoading, error } = await dataUsage(req);
        setLoading(isLoading);

        if (!error) {
          setJson(data);
        }
      }

      if (config.type === "average") {
        const req: DataReqBody = {
          snr: device?.snr,
          from: from,
          to: to,
          bucket: "hour",
          type: config?.units,
        };

        const { data, isLoading, error } = await dataAverageTimeSeries(req);
        setLoading(isLoading);

        if (!error) {
          setJson(data);
        }
      }
    }
  };

  useEffect(() => {
    if (device?.snr) {
      if (selectedView === "raws") {
        fetchData();
      }

      if (selectedView === "aggregates") {
        fetchAggregates();
      }
    }
  }, [device, dateRange, selectedView]);

  return (
    <DeviceCard
      title={t("card.device.database.raw.header")}
      subTitle={
        dateRange &&
        Array.isArray(dateRange) &&
        `${formatDate(dateRange[0], "ddMMMyyyy")} - ${formatDate(
          dateRange[1],
          "ddMMMyyyy"
        )}`
      }
      topRight={
        <div className="flex items-center gap-2">
          <Switch
            id="raws-view-toggle"
            labelKey={t("switch.aggregates.label")}
            returnBoolean
            onChange={(val: any) =>
              setSelectedView(val ? "aggregates" : "raws")
            }
            sizes="md"
            labelPosition="left"
          />
          <DateRangePickerField
            id="date-range"
            onChange={(val) => {
              setDateRange(val);
            }}
            iconOnly
            sizes="xs"
            maxDate={new Date()}
          />
        </div>
      }
    >
      <div className="flex w-full items-center justify-center overflow-auto">
        {!loading ? (
          <>
            <JsonViewer data={json} />
          </>
        ) : (
          <div className="flex items-center justify-center bg-opacity-50">
            {loading ? (
              <BounceLoader loading={loading} />
            ) : (
              <div className="text-center text-gray-500">
                <img
                  src={NoDataSvg}
                  alt="Modal content"
                  style={{ width: "100%", margin: 80 }}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </DeviceCard>
  );
};

export default Raws;
