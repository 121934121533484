import { RootState } from "./store";

export const accountSiteList = (state: RootState) => state.app.sites;

export const rateStructuresList = (state: RootState) =>
  state.app.rateStructures;

export const welcome = (state: RootState) => state.app.welcome;

export const issuesList = (state: RootState) => state.app.issuesList;
