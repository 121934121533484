import { jobPage } from "api/job";
import { JobPageRespData } from "api/types/jobs";
import { t } from "i18next";
import { JobStatusDropdown } from "models/dropdowns/jobStatus";
import defineJobsTablecolumns, {
  JobsPageTableModel,
} from "models/tables/jobsPage";
import { notifySimple } from "providers/toast";
import { useCallback, useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { account } from "store/account/accountSelectors";
import TableCard from "widgets/cards/table";
import Combobox from "widgets/shared/inputs/combobox";
import { SearchInput } from "widgets/shared/inputs/search";
import Table from "widgets/shared/tables/genericTable";

const VerificationJobs = () => {
  const navigate = useNavigate();
  const accountContext = useSelector(account, shallowEqual);

  const [dataSource, setDataSource] = useState<JobPageRespData[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const [filter, setFilter] = useState("");
  const [state, setState] = useState({
    paginationState: { pageIndex: 0, pageSize: 10 },
    sortingState: [{ desc: false, id: "snr" }],
    totCount: 0,
    pageCount: 0,
    stateFilter: undefined,
  });

  const handleNavigation = (
    action: "device" | "devtools",
    row: JobsPageTableModel
  ) => {
    if (accountContext.name !== row?.container) {
      notifySimple(t("toast.no.account.associated"), "warning");
      return;
    }

    if (action === "device") {
      navigate(`/admin/devices/device?snr=${row?.snr}`);
    } else if (action === "devtools") {
      navigate(
        `/admin/devices/devtools?snr=${row?.snr}&job=${row._id}&status=${row.state}`
      );
    }
  };

  // Usage:
  const handleRowClick = (row: JobsPageTableModel) =>
    handleNavigation("devtools", row);

  const columns = defineJobsTablecolumns(
    (info) => handleNavigation("device", info.row.original),
    (info) => handleNavigation("devtools", info.row.original)
  );

  const fetchData = useCallback(async () => {
    const { paginationState, sortingState, stateFilter } = state;
    setIsLoading(true);
    const { data, status, isLoading, error } = await jobPage({
      search: filter,
      pageIndex: paginationState.pageIndex + 1,
      pageSize: paginationState.pageSize,
      sort: sortingState.length > 0 ? sortingState[0].id : "",
      desc: sortingState.length > 0 ? sortingState[0].desc : false,
      stateFilter: stateFilter,
    });
    setIsLoading(isLoading);
    if (!error) {
      setDataSource(data.jobs || []);
      setState((prevState) => ({
        ...prevState,
        paginationState: {
          pageIndex: data.currentPage - 1,
          pageSize: paginationState.pageSize,
        },
        totCount: data.totalCount,
        pageCount: data.totalPages,
      }));
    }
  }, [
    filter,
    state.paginationState.pageIndex,
    state.paginationState.pageSize,
    state.sortingState,
    state.stateFilter,
  ]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className={`px-2  md:px-4`}>
      <TableCard>
        <Table
          filterbar={
            <div className="flex h-full w-full flex-col gap-y-3">
              <div className="grid grid-cols-1 gap-2 lg:grid-cols-5">
                <div className="flex-1 lg:flex-grow-[1]">
                  <Combobox
                    id="device-select-status"
                    placeholder="Select status"
                    variant="filter"
                    value={undefined}
                    onChange={(item) => {
                      let filter: undefined | string[] = undefined;
                      if (item) {
                        filter = [item];
                      }
                      setState((prevState) => ({
                        ...prevState,
                        stateFilter: filter,
                        paginationState: {
                          pageIndex: 0,
                          pageSize: state.paginationState.pageSize,
                        },
                      }));
                    }}
                    items={JobStatusDropdown}
                    labelKey="label"
                    valueKey="value"
                    sizes="md"
                    rounded="rounded-xl"
                    disabled={isLoading}
                  />
                </div>
                <div className="flex flex-col gap-y-3">
                  <SearchInput
                    onChange={(filters) => {
                      setFilter(filters);
                      setState((prevState) => ({
                        ...prevState,
                        paginationState: {
                          pageIndex: 0,
                          pageSize: state.paginationState.pageSize,
                        },
                      }));
                    }}
                    type="text"
                    value={filter}
                    sizes="md"
                    rounded="rounded-xl"
                    disabled={isLoading}
                  />
                </div>
              </div>
            </div>
          }
          loading={isLoading}
          data={dataSource}
          columns={columns}
          pagination={state.paginationState}
          paginationOptions={{
            onPaginationChange: (pagination) => {
              const page =
                typeof pagination === "function"
                  ? pagination(state.paginationState)
                  : pagination;
              setState((prevState) => ({
                ...prevState,
                paginationState: page,
              }));
            },
            rowCount: state.totCount,
            pageCount: state.pageCount,
          }}
          sorting={state.sortingState}
          onSortingChange={(updaterOrValue) => {
            const newSortingState =
              typeof updaterOrValue === "function"
                ? updaterOrValue(state.sortingState)
                : updaterOrValue;

            if (newSortingState && newSortingState.length > 0) {
              setState((prevState) => ({
                ...prevState,
                sortingState: newSortingState,
              }));
            } else {
              setState((prevState) => ({
                ...prevState,
                sortingState: [{ id: "snr", desc: false }],
              }));
            }
          }}
          onRowClick={handleRowClick}
        />
      </TableCard>
    </div>
  );
};

export default VerificationJobs;
