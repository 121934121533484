import { useState, useLayoutEffect } from "react";

const getNavHeight = () => {
  const navBar = document.getElementById("main-content-navbar");
  return navBar ? navBar.clientHeight : 0;
};

const getFooterHeight = () => {
  const footer = document.getElementById("main-content-footer");
  return footer ? footer.clientHeight : 0;
};

const getContentHeight = () => {
  return window.innerHeight - getNavHeight() - getFooterHeight() - 40;
};

const getControlHeight = (refOrId: HTMLDivElement | string): number => {
  const control =
    typeof refOrId === "string" ? document.getElementById(refOrId) : refOrId;

  if (!control) return 0;

  const style = window.getComputedStyle(control);
  if (style.display === "none") return 0; // Handle hidden elements

  return control.clientHeight;
};

const useViewportDimensions = () => {
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
    navHeight: 0,
    footerHeight: 0,
    contentHeight: 0,
  });

  useLayoutEffect(() => {
    const updateDimensions = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
        navHeight: getNavHeight(),
        footerHeight: getFooterHeight(),
        contentHeight: getContentHeight(),
      });
    };

    updateDimensions(); // Run once on mount to set initial values

    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return { dimensions, getControlHeight };
};

export default useViewportDimensions;
