import { t } from "i18next";
import React, {
  useState,
  InputHTMLAttributes,
  forwardRef,
  useRef,
  useImperativeHandle,
} from "react";
import GenericButton from "widgets/shared/buttons/generic";

interface InputFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  id: string;
  extra?: string;
  extraContainer?: string;
  variant?: string;
  state?: "error" | "success"; // Specific states for styling
  centered?: boolean;
  dense?: boolean;
  readonly?: boolean;
  sizes?: "lg" | "md" | "sm" | "xs";
  onChange?: (val: any) => void;
}

const SelectFile = forwardRef<HTMLInputElement, InputFieldProps>(
  (props, ref) => {
    const {
      value,
      id,
      extra,
      extraContainer,
      sizes = "lg",
      placeholder,
      variant,
      state,
      dense,
      disabled,
      readonly,
      centered,
      onChange,
      onBlur,
      onFocus,
      ...rest
    } = props;

    const fileInput = useRef<HTMLInputElement>(null);
    const [file, setFile] = useState<any>();

    const handleChange = (event: any) => {
      setFile(event.target.files[0]);
      onChange(event.target.files[0]);
    };

    // Expose the fileInput ref to the parent component
    useImperativeHandle(ref, () => ({
      ...fileInput.current,
      clear: () => {
        if (fileInput.current) {
          fileInput.current.value = "";
          setFile(null);
        }
      },
    }));

    return (
      <div className="flex items-center gap-2">
        <input
          type="file"
          ref={fileInput}
          onChange={handleChange}
          style={{ display: "none" }}
        />
        <GenericButton
          text={"Choose File"}
          variant="outline"
          size={sizes}
          onClick={() => fileInput.current.click()}
        />
        <label className="text-primary">{file ? file.name : ""}</label>
      </div>
    );
  }
);

export default SelectFile;
