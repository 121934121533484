import { t } from "i18next";
import { MenuRoutePaths } from "models/constants/routes";
import { GoArrowRight } from "react-icons/go";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setIssuesList } from "store/appSlice";
import DashboardCard from "widgets/cards/dashboard";
import CircleButton from "widgets/shared/buttons/circle-button";
import { DashCounts } from "./Issues";

interface GeneralProps {
  count?: number;
  metrics?: DashCounts;
  color:
    | "50"
    | "100"
    | "200"
    | "300"
    | "400"
    | "500"
    | "600"
    | "700"
    | "800"
    | "900";
  textColor?: "primary" | "secondary";
  extra?: string;
  context: string;
}

const Generic: React.FC<GeneralProps> = ({
  count = 0,
  metrics,
  color = "50",
  textColor = "secondary",
  extra = "h-full",
  context,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let colorText = textColor === "primary" ? "text-primary" : "text-secondary";

  const handleRoute = () => {
    switch (context) {
      case "total":
        navigate(`/admin${MenuRoutePaths.DEVICES}`);
        break;
      case "users":
        navigate(`/admin${MenuRoutePaths.USERS}`);
        break;
      case "sites":
        navigate(`/admin${MenuRoutePaths.SITES}`);
        break;
      case "zerocount":
        dispatch(setIssuesList(metrics?.devices));
        navigate(`/admin/devices/issues`);
        break;
      case "down":
        dispatch(setIssuesList(metrics?.devices));
        navigate(`/admin/devices/issues`);
        break;
      case "leaking":
        dispatch(setIssuesList(metrics?.devices));
        navigate(`/admin/devices/issues`);
        break;
      case "weaksignal":
        dispatch(setIssuesList(metrics?.devices));
        navigate(`/admin/devices/issues`);
        break;
      default:
        break;
    }
  };

  return (
    <DashboardCard
      title={t("dashboard.card.title", { context })}
      bgColor={`bg-brand-${color}`}
      textColor={colorText}
      extra={extra}
    >
      <div className="flex-grow"></div>
      <div className={`heading-4xl mb-4 ${colorText} 4xl:mb-0`}>
        {count || metrics?.count || 0}
      </div>
      <div className="absolute bottom-4 right-2">
        <CircleButton
          label={t("dashboard.card.action", { context })}
          size="xs"
          variant="ghost"
          color={textColor}
          icon={GoArrowRight}
          iconPosition="right"
          onClick={handleRoute}
        />
      </div>
    </DashboardCard>
  );
};

export default Generic;
