export const USER = {
  USER: {
    ACCOUNTS: { GET: "user/accounts/get" },
    LINK: "user/link",
    PAGE: "user/page",
    UPDATE: "user/update",
    DELETE_ACCOUNT: "user/deleteAccount",
    UNLINK: "user/unlink",
    GET: { INDEX: "user/get", INSTALLER: "user/get/installer" },
  },
};
