// Imports
import { CellContext, createColumnHelper } from "@tanstack/react-table";
import Progress from "components/progress";
import { t } from "i18next";
import { CONST } from "models/constants";
import { formatDate } from "util/datelib";
import { getJobStatusColor } from "util/status";
import MoreAction, { DropDownItem } from "widgets/moreAction";
import Badge from "widgets/shared/badge";

// Define the structure of the JdTableModel
export class JobsPageTableModel {
  _id: string;
  snr: string;
  actions: string;
  installDate: string;
  container: string;
  state: string;
  installer: string;
  reviewer: string;
  siteName: string;
}
const defineJobsTablecolumns = (
  onViewClick?: (info: CellContext<JobsPageTableModel, any>) => void,
  onDevToolsClick?: (info: CellContext<JobsPageTableModel, any>) => void
) => {
  // Initialize the translation hook and
  const moreMenuItems: DropDownItem[] = [
    {
      label: "View",
      active: true,
      onSelected: onViewClick,
      permissionRole: CONST.ROLE_SUPER,
    },
    {
      label: "Dev Tools",
      active: true,
      onSelected: onDevToolsClick,
      permissionRole: CONST.ROLE_SUPER,
    },
  ];
  // create a column helper for the JdTableModel
  const columnHelper = createColumnHelper<JobsPageTableModel>();

  // Define the columns for the JdTable
  const defineJobsTablecolumns = [
    // Description column
    columnHelper.accessor("snr", {
      enableSorting: true,
      header: () => <span className="">{t("table.header.job.snr")}</span>,
      cell: (info) => <span className="">{info.getValue()}</span>,
      meta: { rowClick: true },
    }),

    columnHelper.accessor("container", {
      enableSorting: false,
      header: () => <span className="">{t("table.header.job.account")}</span>,
      cell: (info) => <span className="">{info.getValue()}</span>,
      meta: { rowClick: true },
    }),

    columnHelper.accessor("siteName", {
      enableSorting: false,
      header: () => <span className="">{t("table.header.job.siteName")}</span>,
      cell: (info) => <span className="">{info.getValue()}</span>,
      meta: { rowClick: true },
    }),

    columnHelper.accessor("installer", {
      enableSorting: true,
      header: () => <span className="">{t("table.header.job.installer")}</span>,
      cell: (info) => info.getValue(),
      meta: { rowClick: true },
    }),

    columnHelper.accessor("installDate", {
      enableSorting: true,
      header: () => (
        <span className="">{t("table.header.job.installedDate")}</span>
      ),
      cell: (info) => (
        <span className="">{formatDate(info.getValue(), "lastcomms")}</span>
      ),
      meta: { rowClick: true },
    }),

    columnHelper.accessor("state", {
      enableSorting: true,
      header: () => <span className="">{t("table.header.job.state")}</span>,
      cell: (info) => (
        <span className="">
          {
            <Badge
              label={info.getValue()}
              color={getJobStatusColor(info.getValue())}
            />
          }
        </span>
      ),
      meta: { rowClick: true },
    }),

    columnHelper.accessor("reviewer", {
      enableSorting: true,
      header: () => <span className="">{t("table.header.job.reviewer")}</span>,
      cell: (info) => info.getValue(),
      meta: { rowClick: true },
    }),

    // Actions column
    columnHelper.accessor("actions", {
      enableSorting: false,
      meta: {
        rowClick: false,
        customClass: "justify-items-center",
        customHeadClass: "justify-items-center",
      },

      header: () => <span className="">{t("table.header.actions")}</span>,
      cell: (info) => (
        <div>
          <MoreAction
            items={moreMenuItems}
            info={info}
            title="Options"
            extra="w-full flex"
          />
        </div>
      ),
    }),
  ];

  return defineJobsTablecolumns;
};

export default defineJobsTablecolumns;
