// Props
type ProfileCardProps = {
  title?: string;
  description?: string;
  extra?: string;
  textColor?: string;
  bgColor?: string;
  children?: React.ReactNode;
  topRight?: React.ReactNode;
  style?: React.CSSProperties;
};

const ProfileCard = ({
  title,
  description,
  extra,
  textColor = "text-primary",
  bgColor = "white",
  children,
  topRight,
  style,
}: ProfileCardProps) => {
  return (
    <div
      className={`!z-5 relative flex w-full flex-col rounded-[20px] p-4 md:p-6 ${bgColor} ${extra}`}
      style={style}
    >
      <div className="mb-5 flex w-full flex-col">
        <div className="flex w-full flex-col justify-between md:flex-row md:items-start">
          <div className="flex flex-col">
            <h4 className={`heading-lg ${textColor}`}>{title}</h4>
            <p className={`${textColor} mt-1 text-txt-md`}>{description}</p>
          </div>

          <div className="mt-4 md:ml-4 md:mt-0">{topRight}</div>
        </div>
      </div>
      {children}
    </div>
  );
};

export default ProfileCard;
