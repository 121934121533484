import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalCloseButton,
} from "@chakra-ui/modal";
import { PortalManager } from "@chakra-ui/portal";
import { deviceDataQualitySet } from "api/device";
import { StatusDropdown } from "models/dropdowns/status";
import { deviceVerifiedIcon } from "providers/icons";
import { notifySimple } from "providers/toast";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setLoading } from "store/appSlice";
import GenericButton from "widgets/shared/buttons/generic";
import Radio from "widgets/shared/inputs/radio";

const DataQualityDialog = (props: {
  isOpen: boolean;
  device: any;
  onClose: () => void;
  onSuccess: () => void;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedRole, setSelectedRole] = useState("");

  // Function for linking an user
  const doDqEdit = async () => {
    dispatch(setLoading(true));

    const { status } = await deviceDataQualitySet(
      props.device.snr,
      selectedRole
    );
    dispatch(setLoading(false));
    if (status === 200) {
      props.onSuccess();
      notifySimple("Status updated", "success");
    } else {
      notifySimple("Failed to update", "error");
    }
  };

  useEffect(() => {
    if (props.device && props.device?.dataQuality) {
      setSelectedRole(props.device.dataQuality);
    }
  }, [props.device]);

  return (
    <>
      <PortalManager zIndex={2000}>
        <Modal isCentered isOpen={props.isOpen} onClose={props.onClose}>
          <ModalOverlay className="bg-[#000] !opacity-30" />
          <ModalContent className="top-[22vh] !m-auto !w-max min-w-80 !max-w-[40%] rounded-md bg-white px-6 md:top-[12vh]">
            <ModalHeader className="heading-md  py-4 text-primary dark:text-darkPrimary">
              {"Data Quality Status"}
            </ModalHeader>
            <ModalCloseButton className="absolute right-[20px] top-[20px] z-10 text-primary dark:text-darkPrimary" />
            <ModalBody className="py-2 text-txt-md text-tertiary">
              <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6">
                {StatusDropdown &&
                  StatusDropdown.map((item, index) => {
                    const { verifiedIcon } = deviceVerifiedIcon(item.label);
                    return (
                      <div
                        className="flex items-center text-sm"
                        key={`role-${index}`}
                      >
                        <Radio
                          checked={selectedRole === item.value}
                          onChange={() => setSelectedRole(item.value)}
                        />
                        <div className="flex items-center gap-2">
                          {item.label}
                          {verifiedIcon}
                        </div>
                      </div>
                    );
                  })}
              </div>

              <div className="flex gap-2 pb-2 pt-4">
                <GenericButton
                  text={t("button.action.cancel")}
                  onClick={() => props.onClose()}
                  variant="ghost"
                  size="md"
                  extra="w-full"
                />
                <GenericButton
                  text={t("button.action.ok")}
                  onClick={doDqEdit}
                  size="md"
                  extra="w-full"
                />
              </div>
            </ModalBody>
          </ModalContent>
        </Modal>
      </PortalManager>
    </>
  );
};

export default DataQualityDialog;
