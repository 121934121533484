import { deviceSet } from "api/device";
import { Device, DeviceSetBody } from "api/types/device";
import { t } from "i18next";
import { DeviceConfiguration } from "models/devices/configuration";
import { useState } from "react";
import { MdEditDocument } from "react-icons/md";
import { useDispatch } from "react-redux";
import { setLoading } from "store/appSlice";
import DeviceCard from "widgets/cards/device";
import ElectricityConfigurationForm from "widgets/forms/devices/configuration/electricity";
import LevelConfigurationForm from "widgets/forms/devices/configuration/level";
import TemperatureConfigurationForm from "widgets/forms/devices/configuration/temperature";
import WaterConfigurationForm from "widgets/forms/devices/configuration/water";
import CircleButton from "widgets/shared/buttons/circle-button";

interface GeneralProps {
  device?: Device;
  config?: DeviceConfiguration;
  onUpdate?: () => void;
}

const Configuration: React.FC<GeneralProps> = ({
  device,
  config,
  onUpdate,
}) => {
  const dispatch = useDispatch();
  const [edit, setEdit] = useState(false);

  const doDeviceSet = async (formData: any) => {
    const body: DeviceSetBody = {
      snr: device?.snr,
    };
    if (formData?.dailyUsageLimit) {
      body.dailyUsageLimit = formData.dailyUsageLimit;
    }
    if (formData?.triggerLow) {
      body.triggerLow = formData.triggerLow;
    }
    if (formData?.triggerHigh) {
      body.triggerHigh = formData.triggerHigh;
    }
    if (formData?.lowLevelPercentage) {
      body.lowLevelPercentage = formData.lowLevelPercentage;
    }
    handleSet(body);
  };

  const handleSet = async (body: DeviceSetBody) => {
    dispatch(setLoading(true));
    const { status, isLoading } = await deviceSet(body);
    dispatch(setLoading(isLoading));

    if (status && status === 200) {
      setEdit(false);
      onUpdate();
    }
  };

  return (
    <DeviceCard
      title={t("device.configuration")}
      config={config}
      topRight={
        <>
          <CircleButton
            icon={MdEditDocument}
            size="md"
            onClick={() => {
              setEdit(!edit);
            }}
            extra="-mt-2"
          />
        </>
      }
    >
      {config && config.units === "water" && (
        <WaterConfigurationForm
          submit={doDeviceSet}
          onClose={() => {}}
          values={{ dailyUsageLimit: device?.dailyUsageLimit }}
          disabled={!edit}
        />
      )}

      {config && config.units === "electricity" && (
        <ElectricityConfigurationForm
          submit={doDeviceSet}
          onClose={() => {}}
          values={{ dailyUsageLimit: device?.dailyUsageLimit }}
          disabled={!edit}
        />
      )}
      {config && config.units === "temperature" && (
        <TemperatureConfigurationForm
          submit={doDeviceSet}
          onClose={() => {}}
          values={{
            triggerLow: device?.triggerLow || "",
            triggerHigh: device?.triggerHigh || "",
          }}
          disabled={!edit}
        />
      )}
      {config && config.units === "level" && (
        <LevelConfigurationForm
          submit={doDeviceSet}
          onClose={() => {}}
          values={{ lowLevelPercentage: device?.lowLevelPercentage }}
          disabled={!edit}
        />
      )}
    </DeviceCard>
  );
};

export default Configuration;
