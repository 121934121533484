export const ReportConfiguration = [
  {
    id: 1,
    name: "Raw Report",
    classSelect: true,
    singleSelect: true,
    multiSelect: false,
    dateRange: true,
    models: undefined,
  },
  {
    id: 2,
    name: "Water Report",
    classSelect: true,
    singleSelect: false,
    multiSelect: true,
    dateRange: true,
    models: ["tag.rhw", "tag.h2o", "tag.wmd"],
  },
  {
    id: 3,
    name: "Electricity Report",
    classSelect: true,
    singleSelect: false,
    multiSelect: true,
    dateRange: true,
    models: ["tag.rhe", "tag.opt"],
  },
  {
    id: 4,
    name: "Hourly Usage Report",
    classSelect: true,
    singleSelect: true,
    multiSelect: false,
    dateRange: true,
    models: ["tag.rhw", "tag.h2o", "tag.wmd"],
  },
  {
    id: 5,
    name: "Uptime Report",
    classSelect: true,
    singleSelect: false,
    multiSelect: true,
    dateRange: true,
    models: ["tag.rhw", "tag.h2o", "tag.wmd", "tag.rhe", "tag.opt"],
  },
];
