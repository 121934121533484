import { usePermissions } from "hooks/usePermissions";

const GenericButton = ({
  loading = false,
  disabled = false,
  size = "lg",
  variant = "solid",
  type = "button",
  text,
  onClick,
  extra,
  color = "primary",
  rounded = "rounded-2xl",
  permissionRole,
}: {
  loading?: boolean;
  disabled?: boolean;
  size?: "lg" | "md" | "sm" | "xs";
  variant?: "solid" | "outline" | "ghost" | "link";
  type?: "button" | "submit";
  text: string;
  onClick?: () => void;
  extra?: string;
  color?: "primary" | "secondary" | "accent";
  rounded?: string;
  permissionRole?: string;
}) => {
  const { canShow } = usePermissions();

  if (permissionRole && !canShow(permissionRole)) {
    return null;
  }

  let typeClass = "";
  let sizeClass = "";
  let opacityClass = disabled || loading ? "opacity-40" : "opacity-100";

  // Dynamically generate color classes based on the color prop
  const colorClasses = {
    primary: {
      solid: "text-white bg-primary hover:opacity-50",
      outline:
        "border border-primary text-primary bg-transparent hover:opacity-50",
      ghost: "text-primary bg-transparent hover:opacity-50",
      link: "text-primary underline hover:opacity-50",
    },
    secondary: {
      solid: "text-primary bg-secondary hover:opacity-50",
      outline:
        "border border-secondary text-secondary bg-transparent hover:opacity-50",
      ghost: "text-secondary bg-transparent hover:opacity-50",
      link: "text-secondary underline hover:opacity-50",
    },
    accent: {
      solid: "text-white bg-brand-800 hover:opacity-50",
      outline:
        "border border-brand-800 text-brand-800 bg-transparent hover:opacity-50",
      ghost: "text-brand-800 bg-transparent hover:opacity-50",
      link: "text-brand-800 underline hover:opacity-50",
    },
  };

  // Set typeClass based on variant and color
  typeClass = colorClasses[color][variant];

  switch (size) {
    case "lg":
      sizeClass = "h-12 px-6 heading-md";
      break;
    case "md":
      sizeClass = "h-10 px-4 heading-sm";
      break;
    case "sm":
      sizeClass = "h-8 px-3  heading-sm";
      break;
    case "xs":
      sizeClass = "h-6 px-2  heading-xs";
      break;
  }

  return (
    <button
      className={`${rounded} ${opacityClass} ${sizeClass} ${typeClass} ${extra}`}
      disabled={loading || disabled}
      onClick={onClick}
      type={type}
    >
      {text}
    </button>
  );
};

export default GenericButton;
