import * as z from "zod";

import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../providers/form";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import GenericButton from "widgets/shared/buttons/generic";
import ComboBox from "widgets/shared/inputs/combobox";
import { shallowEqual, useSelector } from "react-redux";
import { rateStructuresList } from "store/appSelectors";
import { ServiceLevelDropdown } from "models/dropdowns/serviceLevel";
import { BillingPeriodDropdown } from "models/dropdowns/billingPeriod";
import { useEffect } from "react";
import { CollectorDropdown } from "models/dropdowns/collector";
import InputField from "widgets/shared/inputs/inputField";
import { usePermissions } from "hooks/usePermissions";
import { CONST } from "models/constants";
// Schema
const SiteConfigurationSchema = z.object({
  rateStructureId: z.string(),
  serviceLevel: z.string(),
  billingPeriod: z.string(),
  collector: z.string(),
});

export type SiteConfigurationFormType = z.infer<typeof SiteConfigurationSchema>;

// Props
type SiteConfigurationFormProps = {
  submit: (data: SiteConfigurationFormType) => void;
  onClose: () => void;
  defaultValues?: SiteConfigurationFormType;
  values?: SiteConfigurationFormType;
  loading?: boolean;
  disabled?: boolean;
};

const SiteConfigurationForm = ({
  submit,
  onClose,
  defaultValues,
  values,
  loading,
  disabled,
}: SiteConfigurationFormProps) => {
  const form = useForm<SiteConfigurationFormType>({
    resolver: zodResolver(SiteConfigurationSchema),
    defaultValues: {
      rateStructureId: "",
      serviceLevel: "",
      billingPeriod: "",
      collector: "",
    },
    values,
  });

  const ratesStructureList = useSelector(rateStructuresList, shallowEqual);
  const { t } = useTranslation();
  const { canShow } = usePermissions();
  const { errors, isDirty } = form.formState;

  const clearErrors = () => {
    form.clearErrors();
  };

  // Automatically submit the form if valid
  const onSubmit = async (formData: SiteConfigurationFormType) => {
    try {
      await submit(formData);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  useEffect(() => {
    if (disabled && isDirty) {
      form.reset(values || defaultValues);
    }
  }, [disabled, isDirty, form, values, defaultValues]);

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-2">
        <div className="grid grid-cols-1 gap-2 md:grid-cols-2 md:gap-5">
          <FormField
            control={form.control}
            name="rateStructureId"
            rules={{ required: false }}
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-secondary">
                  {"Rates Structure"}
                </FormLabel>
                <FormControl>
                  <ComboBox
                    items={ratesStructureList || []}
                    sizes="md"
                    placeholder={t("form.placeholder.site.billing.period")}
                    labelKey="name"
                    valueKey="id"
                    id="rate-structure-id"
                    type="text"
                    state={errors.rateStructureId ? "error" : undefined}
                    onFocus={clearErrors}
                    disabled={disabled || !canShow(CONST.ROLE_OWNER)}
                    clearIcon={false}
                    color="secondary"
                    variant="solid"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="collector"
            rules={{ required: false }}
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-secondary">{"Collector"}</FormLabel>
                <FormControl>
                  <ComboBox
                    items={CollectorDropdown || []}
                    sizes="md"
                    placeholder={t("form.placeholder.site.billing.period")}
                    labelKey="label"
                    valueKey="value"
                    id="collector"
                    type="text"
                    state={errors.collector ? "error" : undefined}
                    onFocus={clearErrors}
                    disabled={disabled || !canShow(CONST.ROLE_ADMINISTRATOR)}
                    clearIcon={false}
                    {...field}
                    color="secondary"
                    variant="solid"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="grid grid-cols-1 gap-2 md:grid-cols-2 md:gap-5">
          <FormField
            control={form.control}
            name="serviceLevel"
            rules={{ required: false }}
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-secondary">
                  {t("form.input.site.service.level")}
                </FormLabel>
                <FormControl>
                  <ComboBox
                    items={ServiceLevelDropdown}
                    sizes="md"
                    placeholder={t("form.placeholder.site.service.level")}
                    labelKey="label"
                    valueKey="value"
                    id="service-level"
                    type="text"
                    state={errors.serviceLevel ? "error" : undefined}
                    onFocus={clearErrors}
                    disabled={disabled || !canShow(CONST.ROLE_ADMINISTRATOR)}
                    clearIcon={false}
                    color="secondary"
                    variant="solid"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="billingPeriod"
            rules={{ required: false }}
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-secondary">
                  {t("form.input.site.billing.period")}
                </FormLabel>
                <FormControl>
                  <ComboBox
                    items={BillingPeriodDropdown}
                    sizes="md"
                    placeholder={t("form.placeholder.site.billing.period")}
                    labelKey="label"
                    valueKey="value"
                    id="billing-period"
                    type="text"
                    state={errors.billingPeriod ? "error" : undefined}
                    onFocus={clearErrors}
                    disabled={disabled || !canShow(CONST.ROLE_ADMINISTRATOR)}
                    clearIcon={false}
                    color="secondary"
                    variant="solid"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        {isDirty && !disabled && (
          <div className="flex w-full flex-col pt-4">
            <div className="flex justify-end">
              <GenericButton
                text={t("button.action.save.changes")}
                disabled={!isDirty}
                loading={loading || false}
                type="submit"
                color="secondary"
              />
            </div>
          </div>
        )}
      </form>
    </Form>
  );
};

export default SiteConfigurationForm;
