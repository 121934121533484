import { USER } from "./user";
import { DEVICE } from "./device";
import { SITE } from "./site";
import { DATA } from "./data";
import { ADMIN } from "./admin";
import { DASHBOARD } from "./dashboard";
import { RATES } from "./rates";
import { REPORT } from "./report";
import { JOB } from "./job";

export const ENDPOINTS = {
  ...SITE,
  ...DEVICE,
  ...USER,
  ...DATA,
  ...ADMIN,
  ...DASHBOARD,
  ...RATES,
  ...REPORT,
  ...JOB,
};
