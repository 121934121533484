import { CONST } from "models/constants";
import { useSelector } from "react-redux";
import { shallowEqual } from "react-redux";
import { getRole } from "store/account/accountSelectors";

type Role = {
  value: string;
  label: string;
};

export const roles: Role[] = [
  { value: CONST.ROLE_SUPER, label: "Super" },
  { value: CONST.ROLE_ADMINISTRATOR, label: "Administrator" },
  { value: CONST.ROLE_OWNER, label: "Owner" },
  { value: CONST.ROLE_MANAGER, label: "Manager" },
  { value: CONST.ROLE_USER, label: "User" },
];

const roleHierarchy: { [key: string]: number } = {
  [CONST.ROLE_SUPER]: 1,
  [CONST.ROLE_ADMINISTRATOR]: 2,
  [CONST.ROLE_OWNER]: 3,
  [CONST.ROLE_MANAGER]: 4,
  [CONST.ROLE_USER]: 5,
};

export const usePermissions = () => {
  const currentRole = useSelector(getRole, shallowEqual);

  const availableRoles = (): Role[] => {
    return roles.filter(
      (role) => roleHierarchy[role.value] > roleHierarchy[currentRole]
    );
  };

  const availableRolesIncl = (): Role[] => {
    return roles.filter(
      (role) => roleHierarchy[role.value] >= roleHierarchy[currentRole]
    );
  };

  const canNavigate = (role: string): boolean => {
    return roleHierarchy[currentRole] < roleHierarchy[role];
  };

  const canShow = (role: string): boolean => {
    return roleHierarchy[currentRole] <= roleHierarchy[role];
  };

  return {
    availableRoles,
    availableRolesIncl,
    canNavigate,
    canShow,
  };
};
