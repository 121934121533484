import { deviceSet } from "api/device";
import { Device, DeviceSetBody } from "api/types/device";
import { t } from "i18next";
import { CONST } from "models/constants";
import { DeviceConfiguration } from "models/devices/configuration";
import { useState } from "react";
import { MdEditDocument } from "react-icons/md";
import { TbCircuitSwitchOpen } from "react-icons/tb";
import { useDispatch } from "react-redux";
import { setLoading } from "store/appSlice";
import { getReadings } from "util/device";
import DeviceCard from "widgets/cards/device";
import SwitchValveDialog from "widgets/dialogs/switchValve";
import DeviceGeneralForm, {
  DeviceGeneralFormType,
} from "widgets/forms/devices/general";
import CircleButton from "widgets/shared/buttons/circle-button";
import QrCodeButton from "widgets/shared/buttons/qrcode";

interface GeneralProps {
  device?: Device;
  config?: DeviceConfiguration;
  onUpdate?: () => void;
}

const General: React.FC<GeneralProps> = ({ device, config, onUpdate }) => {
  const dispatch = useDispatch();

  const [edit, setEdit] = useState(false);
  const [isValve, setIsValve] = useState(false);

  const doDeviceSet = async (formData: DeviceGeneralFormType) => {
    const body: DeviceSetBody = {
      snr: device?.snr,
      meterNumber: formData.meterNumber,
      meterModel: formData.meterModel,
      address: formData.address,
      unitNumber: formData.unitNumber,
    };

    dispatch(setLoading(true));
    const { status, isLoading } = await deviceSet(body);
    dispatch(setLoading(isLoading));

    if (status && status === 200) {
      setEdit(false);
      onUpdate();
    }
  };

  return (
    <>
      {isValve && (
        <SwitchValveDialog
          isOpen={isValve}
          device={device}
          onClose={() => {
            setIsValve(false);
          }}
          onSuccess={(refresh) => {
            setIsValve(false);
            if (refresh) onUpdate();
          }}
        />
      )}
      <DeviceCard
        showIcon
        title={device?.name}
        subTitle={`${t("generic.serial")}: ${device.snr || ""}`}
        subTitle1={`${t("meter.reading")}: ${getReadings(device)}`}
        config={config}
        topRight={
          <div className="-mt-2 flex items-center">
            {device?.valveState && (
              <CircleButton
                icon={TbCircuitSwitchOpen}
                size="md"
                onClick={() => {
                  setIsValve(!isValve);
                }}
                permissionRole={CONST.ROLE_OWNER}
                color={
                  device?.valve1Synced
                    ? device?.valveState === "OPEN"
                      ? "green"
                      : "red"
                    : "amber"
                }
              />
            )}
            <CircleButton
              icon={MdEditDocument}
              size="md"
              onClick={() => {
                setEdit(!edit);
              }}
              permissionRole={CONST.ROLE_OWNER}
            />
            <QrCodeButton
              data={device?.snr}
              class={`text-secondary`}
              type="snr"
            />
          </div>
        }
      >
        <DeviceGeneralForm
          values={{
            meterNumber: device?.meterNumber || "",
            meterModel: device?.meterModel || "",
            address: device?.address || "",
            unitNumber: device?.unitNumber || "",
          }}
          submit={doDeviceSet}
          onClose={() => {}}
          disabled={!edit}
        />
      </DeviceCard>
    </>
  );
};

export default General;
