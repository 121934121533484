import { Device } from "api/types/device";
import { DeviceConfiguration } from "models/devices/configuration";
import { IconType } from "react-icons";
import {
  MdFlashOn,
  MdQrCode,
  MdThermostat,
  MdWaterDrop,
  MdWaterfallChart,
} from "react-icons/md";

export const getReadings = (device: Device) => {
  const model = device.model;
  switch (model) {
    case "tag.rhw":
    case "tag.h2o":
    case "tag.wmd":
    case "lora.wmd":
    case "bulk.wmd":
      if (device.waterMeter) {
        // Calculate usage in LITRES
        let usage = (device.waterMeter - device.installReading) / device.ratio;
        let odometer = device.installOdo / 10000 + usage / 1000;

        let odo = Math.floor(odometer);
        if (isNaN(odo)) return "Not Available";

        // Pad the odometer value with leading zeros if needed
        let display = odo.toString().padStart(4, "0") + " kL";
        return display;
      } else {
        return "Not Available";
      }
    case "qrw.man":
      if (device.waterMeter) {
        let odo = Math.floor(device.waterMeter);
        if (isNaN(odo)) return "Not Available";

        // Pad the odometer value with leading zeros if needed
        let display = odo.toString().padStart(4, "0");
        return display;
      } else {
        return "Not Available";
      }
    case "tag.tmp":
    case "tag.tmo":
    case "lora.tmp":
    case "lora.nanotag":
      if (device.tempMeter) {
        let raw = device.tempMeter;
        if (model === "tag.tmp") raw = raw / 10;
        return `${raw.toFixed(1)} °C`;
      }
      return `Not Available`;
    case "qrw.pre":
    case "qre.pre":
    case "qrg.pre":
      return null;
    case "tag.rhe":
      if (device.postPaid) {
        return `${device.meterReading} kWh`;
      } else {
        return `${device.userCredit} kWh`;
      }
    // return `Not Available`;
    case "tag.opt":
      if (device.postPaid) {
        return `${device.forwardticks} kWh`;
      } else {
        return `${device.userCredit} kWh`;
      }
    case "tag.lvl":
      if (device.level) {
        if (device.sensorHeight >= 0 && device.overflowHeight >= 0) {
          //Get sensor offset
          let diff = device.sensorHeight - device.overflowHeight;
          //Determine water level distance from overflow
          let actualReading = device.level - diff;
          //Determine current tank percentage level
          let actualPercentage =
            100 - (actualReading / device.overflowHeight) * 100;
          const odo = actualPercentage.toFixed(2).toString();
          return `${odo} %`;
        }

        return `Not Available`;
      }
      return `Not Available`;

    default:
      return `Not Available`;
  }
};

export const getChartColors = (config: DeviceConfiguration) => {
  const model = config?.model || "";
  switch (model) {
    case "tag.rhw":
    case "tag.h2o":
    case "tag.wmd":
    case "lora.wmd":
    case "bulk.wmd":
      return { color0: "var(--color-300)", color1: "#E1E9F8" };

    case "qrw.man":
      return { color0: "var(--color-50)", color1: "#E1E9F8" };
    case "tag.tmp":
    case "tag.tmo":
    case "lora.tmp":
    case "lora.nanotag":
    case "tag.lvl":
      return { color0: "var(--color-800)", color1: "#E1E9F8" };
    case "qrw.pre":
    case "qre.pre":
    case "qrg.pre":
      return { color0: "var(--color-50)", color1: "#E1E9F8" };
    case "tag.rhe":
      return { color0: "var(--color-50)", color1: "#E1E9F8" };
    case "tag.opt":
      return { color0: "var(--color-50)", color1: "#E1E9F8", color3: "#FFD28F" };
    default:
      return { color0: "var(--color-50)", color1: "#E1E9F8" };
  }
};

export const getDeviceIcon = (type: string) => {
  switch (type) {
    case "water":
      return MdWaterDrop;
    case "electricity":
      return MdFlashOn;
    case "temperature":
      return MdThermostat;
    case "level":
      return MdWaterfallChart;
    case "qr_code":
      return MdQrCode;
    default:
      return MdWaterDrop; // Default to MdWaterDrop if the type doesn't match
  }
};

export const getDeviceBgColor = (type: string) => {
  switch (type) {
    case "water":
      return "bg-brand-300";
    case "electricity":
      return "bg-brand-300";
    case "temperature":
      return "bg-brand-500";
    case "level":
      return "bg-brand-500";
    case "qr_code":
      return "bg-brand-300";
    default:
      return "bg-white"; // Default to MdWaterDrop if the type doesn't match
  }
};

export const processData = (device: Device, data: any) => {
  const model = device.model;
  switch (model) {
    case "tag.rhw":
    case "tag.h2o":
    case "tag.wmd":
    case "lora.wmd":
    case "bulk.wmd":
      if (device.waterMeter) {
        // Calculate usage in LITRES
        let usage = (device.waterMeter - device.installReading) / device.ratio;
        let odometer = device.installReading / 10000 + usage / 1000;

        let odo = Math.floor(odometer);
        if (isNaN(odo)) return "Not Available";

        // Pad the odometer value with leading zeros if needed
        let display = odo.toString().padStart(4, "0") + " kL";
        return display;
      } else {
        return "Not Available";
      }
    case "qrw.man":
      if (device.waterMeter) {
        let odo = Math.floor(device.waterMeter);
        if (isNaN(odo)) return "Not Available";

        // Pad the odometer value with leading zeros if needed
        let display = odo.toString().padStart(4, "0");
        return display;
      } else {
        return "Not Available";
      }
    case "tag.tmp":
    case "tag.tmo":
    case "lora.tmp":
    case "lora.nanotag":
      if (device.tempMeter) {
        let raw = device.tempMeter;
        if (model === "tag.tmp") raw = raw / 10;
        return `${raw.toFixed(1)} °C`;
      }
      return `Not Available`;
    case "qrw.pre":
    case "qre.pre":
    case "qrg.pre":
      return null;
    case "tag.rhe":
      if (device.postPaid) {
        return `${device.meterReading} kWh`;
      } else {
        return `${device.userCredit} kWh`;
      }
    // return `Not Available`;
    case "tag.opt":
      if (device.postPaid) {
        return `${device.electricalMeter} kWh`;
      } else {
        return `${device.userCredit} kWh`;
      }
    case "tag.lvl":
      if (data != null) {
        if (device.sensorHeight >= 0 && device.overflowHeight >= 0) {
          //Get sensor offset
          let diff = device.sensorHeight - device.overflowHeight;
          //Determine water level distance from overflow
          let actualReading = data - diff;
          //Determine current tank percentage level
          let actualPercentage =
            100 - (actualReading / device.overflowHeight) * 100;

          return actualPercentage;
        }

        return `N/A`;
      }
      return `N/A`;

    default:
      return `Not Available`;
  }
};
