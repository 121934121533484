// Imports
import { CellContext, createColumnHelper } from "@tanstack/react-table";
import TooltipHorizon from "components/tooltip";
import { t } from "i18next";
import { CONST } from "models/constants";
import { deviceStatusIcon, deviceVerifiedIcon } from "providers/icons";
import { notifySimple } from "providers/toast";
import { MdCopyAll } from "react-icons/md";
import { shallowEqual, useSelector } from "react-redux";
import { accountSiteList } from "store/appSelectors";
import { formatDate } from "util/datelib";
import { getReadings } from "util/device";
import MoreAction, { DropDownItem } from "widgets/moreAction";

// Define the structure of the JdTableModel
export class DevicePageTableModel {
  id: string;
  snr: string;
  model: string;
  classes: string[];
  deployed: boolean;
  lastComms: string;
  created: string;
  host: string;
  name: string;
  meterNumber?: string;
  waterMeter?: number;
  tempMeter?: number;
  electricalMeter?: number;
  forwardticks?: number;
  signal?: number;
  battery?: number;
  installOdo?: number;
  ratio?: number;
  installReading?: number;
  health: string;
  actions: string;
  postPaid?: boolean;
  userCredit?: number;
  level?: number;
  sensorHeight?: number;
  overflowHeight?: number;
  meterReading?: number;
  dataQuality: string;
  address?: string;
  clientName?: string;
  clientPhone?: string;
  clientEmail?: string;
  installerName?: string;
  installDate?: string;
  meterModel?: string;
  dailyUsageLimit?: number;
  photo1?: string;
  photo2?: string;
  photo3?: string;
  recipients: [];
  installLocation?: [];
  lowLevelPercentage?: number;
  valveState?: string;
}
const defineDpTablecolumns = (
  onEditClick?: (info: CellContext<DevicePageTableModel, any>) => void,
  onDataQualityClick?: (info: CellContext<DevicePageTableModel, any>) => void,
  onRemoveClick?: (info: CellContext<DevicePageTableModel, any>) => void,
  options?: { hideActions?: boolean }
) => {
  // Initialize the translation hook and
  const moreMenuItems: DropDownItem[] = [
    {
      label: "View",
      active: true,
      onSelected: onEditClick,
    },
    {
      label: "Data Quality",
      active: true,
      onSelected: onDataQualityClick,
      permissionRole: CONST.ROLE_SUPER,
    },
    {
      label: "Remove",
      active: true,
      onSelected: onRemoveClick,
      permissionRole: CONST.ROLE_MANAGER,
    },
  ];

  const copyToClipboard = (snr: string) => {
    navigator.clipboard.writeText(snr).then(
      () => {
        notifySimple("Copied to Clipboard", "success");
      },
      (err) => {
        notifySimple("Failed to copy", "error");
      }
    );
  };
  // create a column helper for the JdTableModel
  const columnHelper = createColumnHelper<DevicePageTableModel>();

  // Define the columns for the JdTable
  const devicePageTablecolumns = [
    // Reference column
    columnHelper.accessor("snr", {
      enableSorting: true,
      meta: { rowClick: false },
      header: () => <span className="">{t("table.header.device.snr")}</span>,
      cell: (info): JSX.Element => {
        const { verifiedIcon } = deviceVerifiedIcon(
          info.row.original.dataQuality || null
        );

        return (
          <span className="flex flex-grow items-center gap-2 truncate">
            <TooltipHorizon
              extra="custom-class"
              trigger={verifiedIcon}
              content={<span>{`${info.row.original.dataQuality}`}</span>}
              placement="bottom"
            />
            <span className="">{info.getValue()}</span>
            <MdCopyAll
              onClick={() => copyToClipboard(info.getValue())}
              className="cursor-pointer"
            />
          </span>
        );
      },
    }),

    // Description column
    columnHelper.accessor("host", {
      enableSorting: true,
      meta: { rowClick: true },
      header: () => (
        <span className="">{t("table.header.device.address")}</span>
      ),
      cell: (info) => <span className="">{info.getValue()}</span>,
    }),

    // Description column
    columnHelper.accessor("meterNumber", {
      enableSorting: true,
      meta: { rowClick: true },
      header: () => (
        <span className="">{t("table.header.device.meter.number")}</span>
      ),
      cell: (info) => <span className="">{info.getValue()}</span>,
    }),

    columnHelper.accessor("lastComms", {
      enableSorting: true,
      meta: { rowClick: true },
      header: () => (
        <span className="">{t("table.header.device.lastcomms")}</span>
      ),
      cell: (info) => (
        <span className="">{formatDate(info.getValue(), "lastcomms")}</span>
      ),
    }),

    columnHelper.accessor("waterMeter", {
      enableSorting: false,
      meta: { rowClick: true },
      header: () => (
        <span className="">{t("table.header.device.reading")}</span>
      ),
      cell: (info) => (
        <span className="">{getReadings(info.row.original)}</span>
      ),
    }),

    columnHelper.accessor("health", {
      enableSorting: false,
      meta: { rowClick: true },
      header: () => <span className="">{t("table.header.device.health")}</span>,
      cell: (info): JSX.Element => {
        const { batteryIcon, signalIcon, valveIcon } = deviceStatusIcon(
          info.row.original.battery || null,
          info.row.original.signal || null,
          info.row.original.valveState || null
        );

        return (
          <span className="flex flex-grow gap-2 truncate">
            <span>{batteryIcon}</span>

            <TooltipHorizon
              extra="custom-class"
              trigger={signalIcon}
              content={<span>{`${info.row.original.signal}`}</span>}
              placement="bottom"
            />
            <TooltipHorizon
              extra="custom-class"
              trigger={valveIcon}
              content={<span>{`${info.row.original.valveState}`}</span>}
              placement="bottom"
            />
          </span>
        );
      },
    }),

    columnHelper.accessor("classes", {
      enableSorting: false,
      meta: { rowClick: true },
      header: () => <span className="">{t("table.header.device.sites")}</span>,
      cell: (info) => (
        <span className="flex flex-grow flex-row gap-1 truncate">
          {info.getValue().map((item) => {
            const sites = useSelector(accountSiteList, shallowEqual);
            const site = sites.find((a) => a.id === item);
            return site ? site.name : null;
          })}
        </span>
      ),
    }),

    // Actions column
    !options?.hideActions &&
      columnHelper.accessor("actions", {
        enableSorting: false,
        meta: {
          customClass: "justify-items-center",
          customHeadClass: "justify-items-end",
        },
        header: () => <span className="">{t("table.header.actions")}</span>,
        cell: (info) => (
          <div>
            <MoreAction
              items={moreMenuItems}
              info={info}
              title="Device Options"
              extra="w-full flex"
            />
          </div>
        ),
      }),
  ].filter(Boolean);

  return devicePageTablecolumns;
};

export default defineDpTablecolumns;
