import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
} from "@chakra-ui/modal";
import { PortalManager } from "@chakra-ui/portal";
import { t } from "i18next";
import GenericButton from "widgets/shared/buttons/generic";

const GenericDialog = (props: {
  children: React.ReactNode;
  isOpen: boolean;
  title: string;
  onClose?: () => void;
  onSave?: () => void;
  onOk?: () => void;
  onDelete?: () => void;
}) => {
  return (
    <>
      <PortalManager zIndex={2000}>
        <Modal isCentered={true} isOpen={props.isOpen} onClose={props.onClose}>
          <ModalOverlay className="bg-[#000] !opacity-30" />
          <ModalContent className="  top-[6vh] mx-2 rounded-md bg-white sm:top-[12vh] sm:!m-auto sm:!max-w-[60%] md:!max-w-[50%] lg:!max-w-[45%] xl:!max-w-[35%] 2xl:!max-w-[25%]">
            <ModalHeader className="heading-md px-4 py-4 text-primary">
              {props.title}
              <ModalCloseButton className="absolute right-4 top-4 z-10 text-txt-xs text-primary" />
            </ModalHeader>

            <ModalBody className="px-4 py-2 text-txt-md text-tertiary">
              {props.children}
            </ModalBody>
            <ModalFooter className="gap-2 px-4 py-4">
              {props.onClose && (
                <GenericButton
                  onClick={props.onClose}
                  text={t("button.action.close")}
                  size="md"
                  variant="ghost"
                />
              )}
              {props.onSave && (
                <GenericButton
                  onClick={props.onSave}
                  text={t("button.action.save")}
                  size="md"
                />
              )}
              {props.onOk && (
                <GenericButton
                  onClick={props.onOk}
                  text={t("button.action.ok")}
                  size="md"
                />
              )}
              {props.onDelete && (
                <GenericButton
                  onClick={props.onDelete}
                  text={t("button.action.delete")}
                  size="md"
                />
              )}
            </ModalFooter>
          </ModalContent>
        </Modal>
      </PortalManager>
    </>
  );
};

export default GenericDialog;
