import { updateSite } from "api/site";
import { Site, SiteUpdateBody } from "api/types/site";
import { t } from "i18next";
import { CONST } from "models/constants";
import { useState } from "react";
import { MdEditDocument } from "react-icons/md";
import { useDispatch } from "react-redux";
import { setLoading } from "store/appSlice";
import GenericCard from "widgets/cards/generic";
import SiteConfigurationForm, {
  SiteConfigurationFormType,
} from "widgets/forms/sites/configuration";
import CircleButton from "widgets/shared/buttons/circle-button";

interface GeneralProps {
  site?: Site;
  onUpdate?: () => void;
}

const Configuration: React.FC<GeneralProps> = ({ site, onUpdate }) => {
  const dispatch = useDispatch();

  const [edit, setEdit] = useState(false);

  const doUpdateSite = async (formData: SiteConfigurationFormType) => {
    const body: SiteUpdateBody = {
      id: site?.id,
      rateStructureId: formData.rateStructureId,
      collector: formData.collector,
      billingPeriod: formData.billingPeriod,
      serviceLevel: formData.serviceLevel,
    };

    dispatch(setLoading(true));
    const { status, isLoading } = await updateSite(body);
    dispatch(setLoading(isLoading));

    if (status && status === 200) {
      setEdit(false);
      onUpdate();
    }
  };

  return (
    <GenericCard
      title={t("device.configuration")}
      bgColor="bg-brand-500"
      // extra="h-fit"
      extraTitle="text-secondary heading-md"
      topRight={
        <CircleButton
          icon={MdEditDocument}
          size="md"
          onClick={() => {
            setEdit(!edit);
          }}
          extra="-mt-2"
          permissionRole={CONST.ROLE_OWNER}
        />
      }
    >
      <div className="z-10">
        <SiteConfigurationForm
          values={{
            rateStructureId: site?.rateStructureId || "",
            collector: site?.collector || "",
            billingPeriod: site?.billingPeriod || "",
            serviceLevel: site?.serviceLevel || "",
          }}
          submit={doUpdateSite}
          disabled={!edit}
          onClose={() => {}}
        />
      </div>
    </GenericCard>
  );
};

export default Configuration;
