import { usePermissions } from "hooks/usePermissions";
import OverviewDashboard from "./overview/page";
import { Tabs, TabPanels, TabPanel, TabList, Tab } from "@chakra-ui/tabs";
import IssuesDashboard from "./issues/page";
import { MdAlarm, MdOfflineBolt } from "react-icons/md";
import { t } from "i18next";
import useViewportDimensions from "hooks/viewport-dimensions";

const Dashboards = () => {
  const { dimensions } = useViewportDimensions();
  const { canShow } = usePermissions();

  return (
    <div style={{ height: dimensions.contentHeight }}>
      <Tabs variant="plain" isLazy>
        <TabList className="rounded-lg bg-secondary p-2">
          <Tab className="min-w-36 gap-1 rounded-lg p-1 text-primary aria-selected:bg-primary aria-selected:text-white">
            <MdOfflineBolt />
            {t("dashboard.tabs.overview")}
          </Tab>
          <Tab className="min-w-36 gap-1 rounded-lg p-1 text-primary aria-selected:bg-primary aria-selected:text-white">
            <MdAlarm />
            {t("dashboard.tabs.issues")}
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <OverviewDashboard />
          </TabPanel>
          <TabPanel>
            <IssuesDashboard />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </div>
  );
};

export default Dashboards;
