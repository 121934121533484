import React, { useRef, useState, useEffect } from "react";
import Map, { Marker, Popup } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import Card from "components/card";
import { MdFullscreen, MdFullscreenExit, MdPinDrop } from "react-icons/md";
import CircleButton from "widgets/shared/buttons/circle-button";
import TooltipHorizon from "components/tooltip";

const MAPBOX_TOKEN =
  "pk.eyJ1IjoiZGV3ZXRkdCIsImEiOiJja3czd3Y5ZG4wY2ZqMnJtZTA5aHhsdjhzIn0.XFDjM0cckD3GtuuMtQCRWw"; // Set your Mapbox token here

interface MarkerData {
  lat: number;
  lng: number;
  name?: string;
  snr?: string;
}

interface GeneralProps {
  markers?: MarkerData[];
}

const MapCard: React.FC<GeneralProps> = ({ markers }) => {
  const [darkmode, setDarkmode] = useState(
    document.body.classList.contains("dark")
      ? "mapbox://styles/mapbox/dark-v10"
      : "mapbox://styles/mapbox/light-v10"
  );

  const [isFullscreen, setFullscreen] = useState(false);
  const [viewState, setViewState] = useState({
    latitude: -33.805556,
    longitude: 18.869722,
    zoom: 7,
  });

  const [selectedMarker, setSelectedMarker] = useState<MarkerData | null>(null);

  const mapRef = useRef(null);

  useEffect(() => {
    // Adjust map style based on theme changes
    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (
          mutation.type === "attributes" &&
          mutation.attributeName === "class"
        ) {
          if (document.body.classList.contains("dark")) {
            setDarkmode("mapbox://styles/mapbox/dark-v10");
          } else {
            setDarkmode("mapbox://styles/mapbox/light-v10");
          }
        }
      }
    });
    observer.observe(document.body, { attributes: true });
    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    // Disable or enable scrolling based on fullscreen mode
    if (isFullscreen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    if (mapRef.current) {
      mapRef.current.resize();
    }
  }, [isFullscreen]);

  useEffect(() => {
    // Fetch user's current location on mount
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setViewState({
          latitude,
          longitude,
          zoom: 12, // Zoom in to user's location
        });
      },
      (error) => {
        // console.error("Error fetching location:", error);
      },
      { enableHighAccuracy: true }
    );
  }, []);

  return (
    <Card extra={"h-full bg-white"}>
      <div
        className={`${
          isFullscreen ? "fixed inset-0 z-20 bg-white" : "h-full min-h-80"
        }`}
      >
        <Map
          ref={mapRef}
          initialViewState={viewState}
          style={{
            borderRadius: isFullscreen ? "0px" : "16px",
            width: "100%",
            height: "100%",
          }}
          mapStyle={darkmode}
          mapboxAccessToken={MAPBOX_TOKEN}
        >
          {markers &&
            markers.length > 0 &&
            markers.map((marker, index) => (
              <Marker
                key={index}
                latitude={marker.lat}
                longitude={marker.lng}
                onClick={() => setSelectedMarker(marker)}
              >
                <TooltipHorizon
                  extra=""
                  trigger={
                    <MdPinDrop className="h-8 w-8 cursor-pointer text-primary" />
                  }
                  content={
                    <span className="z-30">
                      <div className="">
                        <h3 className="text-txt-lg">{marker.name}</h3>
                        <p className="text-txt-md">{marker.snr}</p>
                      </div>
                    </span>
                  }
                  placement="bottom"
                />
              </Marker>
            ))}

          {selectedMarker && (
            <Popup
              latitude={selectedMarker.lat}
              longitude={selectedMarker.lng}
              onClose={() => setSelectedMarker(null)}
              closeOnClick={false}
              className="rounded-xl p-2"
            >
              <div className="p-2">
                <h3 className="text-txt-lg">{selectedMarker.name}</h3>
                <p className="text-txt-md">{selectedMarker.snr}</p>
              </div>
            </Popup>
          )}
        </Map>

        <CircleButton
          icon={isFullscreen ? MdFullscreenExit : MdFullscreen}
          onClick={() => setFullscreen(!isFullscreen)}
          extra="absolute top-4 right-4"
          size="sm"
        />
      </div>
    </Card>
  );
};

export default MapCard;
