import { uvsDisbursements, uvsUploadXlsx } from "api/admin";
import useViewportDimensions from "hooks/viewport-dimensions";
import LabeledField from "providers/labeledField";
import { notifySimple } from "providers/toast";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setLoading } from "store/appSlice";

import { formatDate, formatDateToZ } from "util/datelib";
import GenericCard from "widgets/cards/generic";
import GenericButton from "widgets/shared/buttons/generic";
import DateRangePickerField from "widgets/shared/inputs/dateRangePicker";
import UploadFile from "widgets/shared/uploadfile";

type LooseValue = Date | [Date, Date] | null;

type Disbursements = {
  link: string;
  name: string;
};

const Uvs = () => {
  const { dimensions } = useViewportDimensions();
  const dispatch = useDispatch();

  const [dateRange, setDateRange] = useState<LooseValue>(null);
  const [disbursements, setDisbursements] = useState<Disbursements[]>([]);

  const handleUploadFile = async (e: any) => {
    dispatch(setLoading(true));
    const { data, isLoading, error } = await uvsUploadXlsx(e);
    dispatch(setLoading(isLoading));

    // Check if the API response contains a URL to download
    if (!error) {
      notifySimple("success", "success");
    } else {
      notifySimple("error", "error");
    }
  };

  const handleDownloadFile = (item: Disbursements) => {
    window.open(item.link, "_blank"); // Open the URL in a new tab
  };

  const handleGetDisbursements = async () => {
    if (dateRange && Array.isArray(dateRange)) {
      const from = formatDateToZ(new Date(dateRange[0]), false); // Start of selected date
      const to = formatDateToZ(new Date(dateRange[1]), true); // End of selected date

      dispatch(setLoading(true));

      const { data, isLoading } = await uvsDisbursements(from, to);
      dispatch(setLoading(isLoading));

      // Check if the API response contains a URL to download
      if (data && data.data) {
        setDisbursements(data.files);
      }
    } else {
      notifySimple("Invalid date range selected", "error");
    }
  };

  return (
    <div
      style={{ height: dimensions.contentHeight }}
      className={`px-2  md:px-4`}
    >
      <GenericCard extra="h-full" bgColor="bg-white" title="UVS Uploads">
        <div className="flex flex-col gap-6">
          <LabeledField label={"Disbursements Uploads"} id={"uploads"}>
            <UploadFile id="upload" onChange={handleUploadFile} />
          </LabeledField>
          <LabeledField
            label={"Disbursements Download"}
            id={"downloads"}
            className="text-txt-lg"
          >
            <div className="flex items-center gap-4">
              <div>
                <DateRangePickerField
                  id={"daterange"}
                  onChange={(val) => {
                    setDateRange(val); // Set new date and trigger useEffect
                  }}
                  maxDate={new Date()}
                  sizes={"md"}
                />
              </div>

              {dateRange && Array.isArray(dateRange) && (
                <label className="text-primary">{`${formatDate(
                  dateRange[0],
                  "long"
                )} - ${formatDate(dateRange[1], "long")}`}</label>
              )}
            </div>
            <GenericButton text={"Download"} onClick={handleGetDisbursements} />
            <div className="grid grid-cols-3 gap-2">
              {disbursements.map((val, index) => {
                return (
                  <>
                    <GenericButton
                      size="md"
                      variant="outline"
                      text={val.name}
                      onClick={() => handleDownloadFile(val)}
                    />
                  </>
                );
              })}
            </div>
          </LabeledField>
        </div>
      </GenericCard>
    </div>
  );
};

export default Uvs;
