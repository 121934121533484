import { gateway } from "./clients/baseClient";
import { ENDPOINTS } from "./endpoints/index";
import { ResponseData } from "./types/api";
import makeApiRequest from "./clients/apiRequest";
import { versionApiPath } from "./common";
import {
  AggregateReqBody,
  RawReqBody,
  UptimeReqBody,
  UsageAggregateReqBody,
  UsageReqBody,
} from "./types/report";

export async function reportRaw(body: RawReqBody): Promise<ResponseData> {
  const data = {
    endpoint: `${versionApiPath}/${ENDPOINTS.REPORT.RAW}`,
    body: body,
  };
  return await makeApiRequest(
    {
      url: ``,
      method: "post",
      data: data,
    },
    gateway,
    false
  );
}

export async function reportUsageBasic(
  body: UsageReqBody
): Promise<ResponseData> {
  const data = {
    endpoint: `${versionApiPath}/${ENDPOINTS.REPORT.DATA.USAGE.BASIC}`,
    body: body,
  };
  return await makeApiRequest(
    {
      url: ``,
      method: "post",
      data: data,
    },
    gateway,
    false
  );
}

export async function reportUsageAggregate(
  body: UsageAggregateReqBody
): Promise<ResponseData> {
  const data = {
    endpoint: `${versionApiPath}/${ENDPOINTS.REPORT.DATA.USAGE.AGGREGATE}`,
    body: body,
  };
  return await makeApiRequest(
    {
      url: ``,
      method: "post",
      data: data,
    },
    gateway,
    false
  );
}

export async function reportUptime(body: UptimeReqBody): Promise<ResponseData> {
  const data = {
    endpoint: `${versionApiPath}/${ENDPOINTS.REPORT.DATA.UPTIME}`,
    body: body,
  };
  return await makeApiRequest(
    {
      url: ``,
      method: "post",
      data: data,
    },
    gateway,
    false
  );
}

export async function reportAggregate(
  body: AggregateReqBody
): Promise<ResponseData> {
  const data = {
    endpoint: `${versionApiPath}/${ENDPOINTS.REPORT.AGGREGATE}`,
    body: body,
  };
  return await makeApiRequest(
    {
      url: ``,
      method: "post",
      data: data,
    },
    gateway,
    false
  );
}
