import { useSearchParams } from "react-router-dom";
import Delete from "./components/Delete";
import Details from "./components/Details";
import Role from "./components/Role";
import Sites from "./components/Sites";
import { useCallback, useEffect, useState } from "react";
import { userEdit, userGet } from "api/user";
import { setLoading } from "store/appSlice";
import { useDispatch } from "react-redux";
import { UserDetails, UserLinkReqBody } from "api/types/user";
import { UserEditFormType } from "widgets/forms/users/edit";
import { UserSitesFormType } from "widgets/forms/users/sites";
import { notifySimple } from "providers/toast";
import useViewportDimensions from "hooks/viewport-dimensions";

const User = () => {
  const { dimensions, getControlHeight } = useViewportDimensions();
  const dispatch = useDispatch();
  const [user, setUser] = useState<UserDetails>({
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    role: "",
    classes: [],
  });
  const [searchParams] = useSearchParams();
  // Extract the filter parameter
  const id = searchParams.get("id");

  const updatePage = () => {
    fetchData();
  };

  const fetchData = useCallback(async () => {
    dispatch(setLoading(true));
    const { data, error, isLoading, status } = await userGet(id);
    dispatch(setLoading(isLoading));

    if (status === 200) {
      setUser(data);
    }
  }, [id]);

  useEffect(() => {
    fetchData();
  }, [id]);

  const handleRoleSubmit = (formData: UserEditFormType) => {
    setUser((prevUser) => {
      const updatedUser = {
        ...prevUser,
        role: formData.role,
      };
      updateLinkedUser(updatedUser); // Pass the updated user
      return updatedUser;
    });
  };

  const handleSitesSubmit = (formData: UserSitesFormType) => {
    setUser((prevUser) => {
      const updatedUser = {
        ...prevUser,
        classes: formData.classes,
      };
      updateLinkedUser(updatedUser); // Pass the updated user
      return updatedUser;
    });
  };

  const updateLinkedUser = useCallback(async (updatedUser: UserDetails) => {
    const body: UserLinkReqBody = {
      email: updatedUser.email,
      role: updatedUser.role,
      classes: updatedUser.classes,
    };
    dispatch(setLoading(true));
    const { data, error, isLoading, status } = await userEdit(body);
    dispatch(setLoading(isLoading));

    if (status === 200) {
      notifySimple("Success", "success");
    }
  }, []);

  return (
    <div
      className="flex w-full flex-col gap-2  px-2 md:px-4"
      style={{ height: dimensions.contentHeight }}
    >
      <div
        id="user-top-grid"
        className="grid grid-cols-1 gap-2  lg:grid-cols-3"
      >
        <Details
          firstName={user.firstName}
          lastName={user.lastName}
          email={user.email}
        />

        <Role role={user.role} onSubmit={handleRoleSubmit} />

        <Delete email={user.email} />
      </div>

      <div className={`grid grid-cols-1`}>
        <Sites classes={user.classes} onSubmit={handleSitesSubmit} />
      </div>
    </div>
  );
};

export default User;
