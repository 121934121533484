import { getSignedUrl } from "api/admin";
import { deviceBulkUpload } from "api/device";
import { DeviceBulkUploadBody } from "api/types/device";
import { useRef, useState } from "react";
import { MdDownload } from "react-icons/md";
import { useDispatch } from "react-redux";
import { setLoading } from "store/appSlice";
import GenericCard from "widgets/cards/generic";
import BulkUploadForm, {
  BulkUploadFormType,
} from "widgets/forms/support/bulkUpload";
import CircleButton from "widgets/shared/buttons/circle-button";
import SelectFile from "widgets/shared/selectFile";
import * as XLSX from "xlsx";
import BulkUploadSvg from "../../../../assets/svg/bulk-upload.svg";
import useViewportDimensions from "hooks/viewport-dimensions";

const BulkUpload = () => {
  const { dimensions } = useViewportDimensions();
  const dispatch = useDispatch();
  const [jsonData, setJsonData] = useState(null);
  const [newData, setNewJsonData] = useState(null);
  const fileInputRef = useRef(null); // Add a reference to the file input element

  const handleFileUpload = (file: any) => {
    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result as ArrayBuffer);
        const workbook = XLSX.read(data, { type: "array" });

        // Assuming first sheet is the main sheet
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        // Convert sheet to JSON
        const parsedData = XLSX.utils.sheet_to_json(sheet);
        const processedData = processParsedData(parsedData);

        setJsonData(processedData);
      };

      reader.readAsArrayBuffer(file);
    }
  };
  const processParsedData = (data: any[]) => {
    // Get all unique keys from the data
    const allKeys = Array.from(
      new Set(data.flatMap((item) => Object.keys(item)))
    );

    return data.map((item) => {
      const newItem: any = {};
      allKeys.forEach((key) => {
        const newKey = key.toLowerCase().replace("(optional)", "").trim();
        let newValue = item[key];
        if (typeof newValue === "string") {
          newValue = newValue.replace("(optional)", "").trim();
        }
        newItem[newKey] =
          newValue !== undefined && newValue !== null ? String(newValue) : "";
      });
      return newItem;
    });
  };
  const downloadExcel = async () => {
    dispatch(setLoading(true));
    const { data, isLoading } = await getSignedUrl("bulkUploadExcel.xlsx");
    dispatch(setLoading(isLoading));

    if (data?.signedUrl) {
      const link = document.createElement("a");
      link.href = data?.signedUrl;
      link.target = "_blank";
      link.download = "bulkUpload.xlsx";
      link.click();
    }
  };

  const doBulkUpload = async (form: BulkUploadFormType) => {
    setLoading(true);
    setNewJsonData(form);
    const { data, status, isLoading } = await deviceBulkUpload(
      form as DeviceBulkUploadBody
    );
    setLoading(isLoading);

    if (status && status === 200) {
      setJsonData(data);
    }
  };

  const clearData = () => {
    setJsonData(null);
    setNewJsonData(null);
    if (fileInputRef.current) {
      fileInputRef.current.clear(); // Use the clear method to reset the file input element's value
    }
  };

  return (
    <div
      style={{ height: dimensions.contentHeight }}
      className={`px-2  md:px-4`}
    >
      <GenericCard
        extra="h-full"
        bgColor="bg-white"
        title="Bulk Device Upload"
        topRight={
          <>
            <CircleButton
              label="Download Template"
              icon={MdDownload}
              onClick={() => {
                downloadExcel();
              }}
              size="md"
              variant="outline"
              // color="secondary"
            />
          </>
        }
      >
        <div className="flex flex-col gap-6">
          <SelectFile
            id={"bulkuploadxlsx"}
            onChange={handleFileUpload}
            sizes="sm"
            ref={fileInputRef}
          />
          {jsonData && jsonData.length > 0 ? (
            <>
              <BulkUploadForm
                submit={doBulkUpload}
                clearData={clearData}
                onClose={function (): void {}}
                values={{
                  devices: jsonData,
                }}
              />
              <div className={`mb-2 grid grid-cols-2 gap-2`}>
                <pre className="whitespace-pre-wrap break-words rounded-md bg-gray-100 p-4">
                  {JSON.stringify(jsonData, null, 2)}
                </pre>
                <pre className="whitespace-pre-wrap break-words rounded-md bg-gray-100 p-4">
                  {newData && newData.devices
                    ? JSON.stringify(newData.devices, null, 2)
                    : ""}
                </pre>
              </div>
            </>
          ) : (
            <div className="mt-12 flex justify-center">
              <img
                src={BulkUploadSvg}
                alt="Modal content"
                style={{ width: "20%" }}
              />
            </div>
          )}
        </div>
      </GenericCard>
    </div>
  );
};

export default BulkUpload;
