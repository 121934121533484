"use client";

import { usePermissions } from "hooks/usePermissions";
import { IconType } from "react-icons";

const CircleButton = ({
  loading = false,
  disabled = false,
  size = "lg",
  variant = "solid",
  type = "button",
  color = "primary",
  label,
  onClick,
  extra,
  icon: Icon,
  iconPosition = "left",
  iconColor = "white",
  permissionRole,
}: {
  loading?: boolean;
  disabled?: boolean;
  size?: "lg" | "md" | "sm" | "xs" | "xxs";
  variant?: "solid" | "outline" | "ghost";
  type?: "button" | "submit";
  color?: "primary" | "secondary" | "red" | "green" | "amber";
  label?: string;
  onClick?: () => void;
  extra?: string;
  icon?: IconType;
  iconColor?: string;
  iconPosition?: "left" | "right";
  permissionRole?: string;
}) => {
  const { canShow } = usePermissions();

  if (permissionRole && !canShow(permissionRole)) {
    return null;
  }

  let typeClass = "";
  let sizeClass = "";
  let sizeIconClass = "";
  let labelClass = "";
  let colorClass = "text-black";
  let opacityClass = disabled || loading ? "opacity-40" : "opacity-100";

  // Dynamically generate color classes based on the color prop
  const colorClasses = {
    primary: {
      solid: "text-white bg-brand-500 hover:opacity-50",
      outline:
        "border border-brand-500 text-brand-500 bg-transparent hover:opacity-50",
      ghost: "text-brand-50 bg-transparent hover:opacity-50",
      link: "text-brand-500 underline hover:opacity-50",
    },
    secondary: {
      solid: "text-white secondary hover:opacity-50",
      outline:
        "border border-secondary text-secondary bg-transparent hover:opacity-50",
      ghost: "text-secondary bg-transparent hover:opacity-50",
      link: "text-secondary underline hover:opacity-50",
    },
    green: {
      solid: "text-white bg-green-500 hover:opacity-50",
      outline: "border green green bg-transparent hover:opacity-50",
      ghost: "green bg-transparent hover:opacity-50",
      link: "green underline hover:opacity-50",
    },
    red: {
      solid: "text-white bg-red-500 hover:opacity-50",
      outline:
        "border border-secondary text-secondary bg-transparent hover:opacity-50",
      ghost: "text-secondary bg-transparent hover:opacity-50",
      link: "text-secondary underline hover:opacity-50",
    },
    amber: {
      solid: "text-white bg-amber-500 hover:opacity-50",
      outline:
        "border border-secondary text-secondary bg-transparent hover:opacity-50",
      ghost: "text-secondary bg-transparent hover:opacity-50",
      link: "text-secondary underline hover:opacity-50",
    },
  };

  // Set typeClass based on variant and color
  typeClass = colorClasses[color][variant];

  switch (size) {
    case "lg":
      sizeClass = "h-12 heading-md";
      sizeIconClass = "h-10 w-10 p-0";
      labelClass = `${Icon ? "pr-4" : "px-4"}`;
      break;
    case "md":
      sizeClass = "h-10 heading-sm";
      sizeIconClass = "h-8 w-8 p-1";
      labelClass = `${Icon ? "pr-3" : "px-3"}`;

      break;
    case "sm":
      sizeClass = "h-8 heading-xs";
      sizeIconClass = "h-6 w-6";
      labelClass = `${Icon ? "pr-3" : "px-3"}`;

      break;
    case "xs":
      sizeClass = "h-6 heading-xs";
      sizeIconClass = "h-4 w-4";
      labelClass = `${Icon ? "pr-2.5" : "px-2.5"}`;
      break;
    case "xxs":
      sizeClass = "h-4 heading-xs";
      sizeIconClass = "h-3 w-3";
      labelClass = `${Icon ? "pr-2.5" : "px-2.5"}`;
      break;
  }

  return (
    <button
      // Apply various classes for styling
      className={`linear flex cursor-pointer flex-row items-center rounded-full ${opacityClass} ${sizeClass} ${typeClass} ${extra}`}
      disabled={loading || disabled}
      onClick={onClick}
      type={type}
    >
      <div className={`flex items-center gap-0 px-1`}>
        {iconPosition === "left" && Icon && (
          <Icon className={`${sizeIconClass} ${iconColor}`} />
        )}

        {label && <span className={`${labelClass}`}>{label}</span>}

        {iconPosition === "right" && Icon && (
          <Icon className={`${sizeIconClass} ${iconColor}`} />
        )}
      </div>
    </button>
  );
};

export default CircleButton;
