import useViewportDimensions from "hooks/viewport-dimensions";

// Props
type TableCardProps = {
  extra?: string;
  children?: React.ReactNode;
};

const TableCard = ({ extra, children }: TableCardProps) => {
  const { dimensions } = useViewportDimensions();
  return (
    <div
      style={{ height: dimensions.contentHeight }}
      className={`relative flex flex-col rounded-[20px] bg-white px-4 dark:!bg-navy-800 dark:text-white md:px-6 ${extra}`}
    >
      {children}
    </div>
  );
};

export default TableCard;
