import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface DevicePageFilters {
  paginationState: { pageIndex: number; pageSize: number };
  sortingState: { desc: boolean; id: string }[];
  totCount: number;
  pageCount: number;
  classId: string;
  model: any;
  status?: string;
  installed?: string;
  search?: string;
}

type DashboardAction = {
  deviceCount: any;
  zeroCount: any;
  devicesDown: any;
  leaking: any;
  weakSignal: any;
  water: any;
  electricity: any;
  temperature: any;
  level: any;
};

type DashboardOverview = {
  data: any;
  counts: any;
};

interface PageState {
  devicePageFilters: DevicePageFilters;
  dashboardOverview: DashboardOverview;
  dashboardActions: DashboardAction;
}

const initialState: PageState = {
  devicePageFilters: {
    paginationState: { pageIndex: 0, pageSize: 10 },
    sortingState: [{ desc: true, id: "name" }],
    totCount: 0,
    pageCount: 0,
    classId: "",
    model: "",
    status: undefined,
    installed: undefined,
    search: "",
  },
  dashboardOverview: {
    data: undefined,
    counts: undefined,
  },
  dashboardActions: {
    deviceCount: undefined,
    zeroCount: undefined,
    devicesDown: undefined,
    leaking: undefined,
    weakSignal: undefined,
    water: undefined,
    electricity: undefined,
    temperature: undefined,
    level: undefined,
  },
};

const pageSlice = createSlice({
  name: "page",
  initialState,
  reducers: {
    setDevicePageFilters: (state, action: PayloadAction<DevicePageFilters>) => {
      state.devicePageFilters = action.payload;
    },
    setDashboardOverview: (state, action: PayloadAction<DashboardOverview>) => {
      state.dashboardOverview = action.payload;
    },
    setDashboardActions: (state, action: PayloadAction<DashboardAction>) => {
      state.dashboardActions = action.payload;
    },
  },
});

export const {
  setDevicePageFilters,
  setDashboardOverview,
  setDashboardActions,
} = pageSlice.actions;
export default pageSlice.reducer;
