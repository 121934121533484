import { uvsDisbursements, uvsUploadXlsx } from "api/admin";
import { notifySimple } from "providers/toast";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setLoading } from "store/appSlice";
import { formatDateToZ } from "util/datelib";
import Uvs from "./uvs/page";
import Rates from "./rates/page";
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
} from "@chakra-ui/accordion";
import { ViewPort } from "models/constants/styling";
import BulkUpload from "./bulkupload/page";
import { usePermissions } from "hooks/usePermissions";
import { CONST } from "models/constants";
import SupportCard from "widgets/cards/support";
import { useNavigate } from "react-router-dom";

type LooseValue = Date | [Date, Date] | null;

type Disbursements = {
  link: string;
  name: string;
};

const Support = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { canShow } = usePermissions();

  const [dateRange, setDateRange] = useState<LooseValue>(null);
  const [disbursements, setDisbursements] = useState<Disbursements[]>([]);

  const handleUploadFile = async (e: any) => {
    dispatch(setLoading(true));
    const { data, isLoading, error } = await uvsUploadXlsx(e);
    dispatch(setLoading(isLoading));

    // Check if the API response contains a URL to download
    if (!error) {
      notifySimple("success", "success");
    } else {
      notifySimple("error", "error");
    }
  };

  return (
    <div>
      <div className="flex flex-wrap gap-4">
        <SupportCard
          title="UVS"
          bgColor="50"
          textColor="secondary"
          onClick={() => navigate(`/admin/support/uvs`)}
        />
        <SupportCard
          title="Rates"
          bgColor="500"
          textColor="secondary"
          onClick={() => navigate(`/admin/support/rates`)}
        />
        <SupportCard
          title="Bulk Upload"
          bgColor="300"
          textColor="primary"
          onClick={() => navigate(`/admin/support/bulkUpload`)}
        />
        <SupportCard
          title="Jobs"
          bgColor="800"
          textColor="primary"
          onClick={() => navigate(`/admin/support/verificationJobs`)}
        />
      </div>
    </div>
  );
};

export default Support;
