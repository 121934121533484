import { PortalManager } from "@chakra-ui/portal";
import { Tooltip } from "@chakra-ui/tooltip";
const TooltipHorizon = (props: {
  extra: string;
  trigger: JSX.Element;
  content: JSX.Element;
  placement: "left" | "right" | "top" | "bottom";
}) => {
  const { extra, trigger, content, placement } = props;
  return (
    <PortalManager zIndex={2000}>
      <Tooltip
        placement={placement}
        label={content}
        shouldWrapChildren
        className={`z-50 flex w-max justify-center rounded-xl bg-white px-4 py-3 text-txt-sm text-primary shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none ${extra}`}
      >
        {trigger}
      </Tooltip>
    </PortalManager>
  );
};

export default TooltipHorizon;
