import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalCloseButton,
} from "@chakra-ui/modal";
import { PortalManager } from "@chakra-ui/portal";
import LabeledField from "providers/labeledField";
import { useTranslation } from "react-i18next";

const InstallerDetailsDialog = ({
  installer,
  isOpen,
  onClose,
}: {
  installer: any;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <PortalManager zIndex={2000}>
        <Modal isCentered isOpen={isOpen} onClose={onClose}>
          <ModalOverlay className="bg-[#000] !opacity-30" />
          <ModalContent className="top-[22vh] !m-auto !w-max min-w-80 !max-w-[40%] rounded-md bg-white md:top-[12vh]">
            <ModalHeader className="heading-md px-6 py-4 text-primary dark:text-darkPrimary">
              {t("dialog.installer.title")}
            </ModalHeader>
            <ModalCloseButton className="absolute right-[20px] top-[20px] z-10 text-primary dark:text-darkPrimary" />
            <ModalBody className="px-6 py-2 text-txt-md text-tertiary">
              <div className="p- grid grid-cols-1 gap-1 pb-2 md:gap-2">
                <LabeledField
                  label={`${t("device.installer.name")}: `}
                  id={"name"}
                  className="text-txt-sm"
                >
                  <label
                    className={`whitespace-normal break-words text-txt-md font-bold`}
                  >
                    {`${installer?.firstName || ""} ${
                      installer?.lastName || ""
                    }`}
                  </label>
                </LabeledField>
                <LabeledField
                  label={`${t("device.installer.email")}: `}
                  id={"date"}
                  className="text-txt-sm"
                >
                  <label
                    className={`whitespace-normal break-words text-txt-md font-bold`}
                  >
                    {installer?.email || ""}
                  </label>
                </LabeledField>
                <LabeledField
                  label={`${t("device.installer.phone")}: `}
                  id={"location"}
                  className="text-txt-sm"
                >
                  <label
                    className={`whitespace-normal break-words text-txt-md font-bold`}
                  >
                    {installer?.phone || ""}
                  </label>
                </LabeledField>
              </div>
            </ModalBody>
          </ModalContent>
        </Modal>
      </PortalManager>
    </>
  );
};

export default InstallerDetailsDialog;
