import { useDispatch } from "react-redux";
import GenericCard from "widgets/cards/generic";
import RatesSetup from "./components/ratesSetup";
import RateStructureSetup from "./components/rateStructureSetup";
import { useState } from "react";
import useViewportDimensions from "hooks/viewport-dimensions";

const Rates = () => {
  const { dimensions } = useViewportDimensions();
  const dispatch = useDispatch();
  const [rateId, setRateId] = useState("");

  return (
    <div
      style={{ height: dimensions.contentHeight }}
      className={`px-2  md:px-4`}
    >
      <GenericCard extra="h-full">
        <div className={`flex h-full flex-col gap-2`}>
          <RateStructureSetup
            onUpdate={(val) => {
              setRateId(val);
            }}
          />
          <RatesSetup rateId={rateId} />
        </div>
      </GenericCard>
    </div>
  );
};

export default Rates;
