import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalCloseButton,
} from "@chakra-ui/modal";
import { PortalManager } from "@chakra-ui/portal";
import { deviceAdd } from "api/device";
import { DeviceAddBody } from "api/types/device";
import { notifySimple } from "providers/toast";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setLoading } from "store/appSlice";
import DeviceForm, { DeviceFormType } from "widgets/forms/devices/device";

const AddDeviceDialog = (props: {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Function for linking an user
  const doLinkUser = async (formData: DeviceFormType) => {
    dispatch(setLoading(true));
    const newDevice: DeviceAddBody = {
      model: formData.model,
      snr: formData.snr,
      name: formData.name,
    };
    const { status } = await deviceAdd(newDevice);
    dispatch(setLoading(false));
    if (status === 200) {
      props.onSuccess();
      notifySimple("New Device Added", "success");
    } else {
      notifySimple("Failed to Add device", "error");
    }
  };

  return (
    <>
      <PortalManager zIndex={2000}>
        <Modal isCentered isOpen={props.isOpen} onClose={props.onClose}>
          <ModalOverlay className="bg-[#000] !opacity-30" />
          <ModalContent className="top-[22vh] !m-auto !w-max min-w-80 !max-w-[40%] rounded-md bg-white md:top-[12vh]">
            <ModalHeader className="heading-md px-6 py-4 text-primary dark:text-darkPrimary">
              {t("dialog.add.device.title")}
            </ModalHeader>
            <ModalCloseButton className="absolute right-[20px] top-[20px] z-10 text-primary dark:text-darkPrimary" />
            <ModalBody className="px-6 py-2 text-txt-md text-tertiary">
              <DeviceForm
                submit={(data) => {
                  doLinkUser(data);
                }}
                onClose={props.onClose}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      </PortalManager>
    </>
  );
};

export default AddDeviceDialog;
