import { dataAverageTimeSeries } from "api/data";
import { DataReqBody } from "api/types/data";
import { Device } from "api/types/device";
import { useEffect, useState } from "react";

import { formatDate, formatDateToZ } from "util/datelib";
import GenericCard from "widgets/cards/generic";
import Combobox from "widgets/shared/inputs/combobox";
import DatePickerField from "widgets/shared/inputs/datePicker";
import PropagateLoader from "react-spinners/PropagateLoader";
import { t } from "i18next";
import { DeviceConfiguration } from "models/devices/configuration";
import { getChartColors, processData } from "util/device";
import LineChart from "components/charts/LineChart";
import { TimeSeriesChartDropdown } from "models/dropdowns/timeSeriesChart";
import DeviceCard from "widgets/cards/device";
import NoDataSvg from "assets/svg/no-data.svg";

const options = {
  chart: {
    type: "rangeBar",
    stacked: false,
    toolbar: { show: false },
    height: "100%",
    width: "100%",
    responsive: [
      {
        breakpoint: 768,
        options: {
          plotOptions: {
            bar: {
              borderRadius: 2,
              columnWidth: "70%",
            },
          },
          xaxis: {
            labels: { rotate: -30 },
          },
        },
      },
    ],
  },
  colors: ["var(--color-800)", "#8eeBaa", "#f87171", "#f87171"],
  // dataLabels: {
  //   enabled: true,
  //   enabledOnSeries: [3],
  //   formatter: function (val: any) {
  //     return val.toFixed(2);
  //   },
  //   orientation: "vertical",
  //   textAnchor: "top",
  //   distributed: false,
  //   offsetX: 0,
  //   offsetY: 0,
  //   style: {
  //     fontSize: "14px",
  //     fontFamily: "Helvetica, Arial, sans-serif",
  //     fontWeight: "bold",
  //     colors: ["#000"],
  //   },
  // },
  plotOptions: {
    bar: {
      isDumbbell: true,
      columnWidth: "40%",
      borderRadius: 2,
    },
  },
  stroke: {
    curve: "smooth",
    width: 2, // Optional: adjust line thickness
  },
  markers: {
    size: 0, // Ensures no data points are shown for the line charts
  },

  fill: {
    colors: ["var(--color-800)", "#E396ee", "#8eeBaa"],
  },

  xaxis: {
    labels: {
      rotate: -45,
      formatter: function (value: string) {
        return `${value}`;
      },
      style: {
        lineHeight: "1.125rem",
        letterSpacing: "-0.02em",
        fontWeight: "500",
        fontFamily: "Lato, sans-serif",
        colors: "var(--color-50)",
      },
    },
    tickPlacement: "off",
  },
  yaxis: {
    labels: {
      formatter: function (value: number) {
        return `${value.toFixed(2)} kl`;
      },
      style: {
        lineHeight: "1.125rem",
        letterSpacing: "-0.02em",
        fontWeight: "700",
        fontFamily: "Lato, sans-serif",
        colors: ["var(--color-50)"],
      },
    },
    min: 0,
    max: 1,
    tickAmount: 3,
  },
  legend: {
    show: false,
  },
  grid: {
    strokeDashArray: 3,
  },
  annotations: {
    yaxis: [
      {
        y: 0, // Replace with the value of the first entry for the first line chart
        label: {
          text: "0.8 kl", // Replace with the actual value
          offsetX: 0,
          offsetY: 10,
          borderColor: "#f87171",
          style: {
            color: "#fff",
            background: "#f87171",
          },
        },
      },
    ],
  },
};

// Define the props for the Role component
interface GeneralProps {
  device?: Device;
  config?: DeviceConfiguration;
}

const Timeseries: React.FC<GeneralProps> = ({ device, config }) => {
  const { color0, color1 } = getChartColors(config || undefined);
  const [chartOptions, setChartOptions] = useState(options);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState([]);
  const [date, setDate] = useState<any>(new Date());
  const [filter, setFilter] = useState<any>({
    calendar: "year",
    xFormat: "month",
    currentBucket: "day",
  });

  const setXFormat = (filter: string) => {
    if (filter === "month") {
      setFilter({ calendar: filter, xFormat: "day", currentBucket: "hour" });
    } else if (filter === "year") {
      setFilter({ calendar: filter, xFormat: "month", currentBucket: "day" });
    } else {
      setFilter({ calendar: filter, xFormat: "year", currentBucket: "month" });
    }
  };

  const fetchData = async () => {
    setLoading(true);

    const req: DataReqBody = {
      snr: device?.snr,
      from: formatDateToZ(date, false, filter.xFormat),
      to: formatDateToZ(date, true, filter.xFormat),
      bucket: filter.currentBucket,
      type: config.units,
    };

    // Update state for date range
    setStartDate(formatDateToZ(date, false, filter.xFormat));
    setEndDate(formatDateToZ(date, true, filter.xFormat));

    const {
      data: newData,
      error: err,
      isLoading,
    } = await dataAverageTimeSeries(req);
    setLoading(isLoading);

    if (!err) {
      const set0 = [];
      const set1 = [];
      const set2 = [];
      const set3 = [];

      for (let index = 0; index < newData.length; index++) {
        const element = newData[index];
        const usage = {
          x: element.date,
          y: [
            config.units === "level"
              ? processData(device, element.max)
              : element.min,
            config.units === "level"
              ? processData(device, element.min)
              : element.max,
          ],
        };
        const min = {
          x: element.date,
          y: device?.triggerLow || 0,
        };
        const max = {
          x: element.date,
          y: device?.triggerHigh || device?.lowLevelPercentage || 0,
        };
        const avg = {
          x: element.date,
          // y: element.avg || 0,
          y:
            config.units === "level"
              ? processData(device, element.avg)
              : element.avg || 0,
        };
        set0.push(usage);
        set1.push(avg);
        set2.push(max);
        set3.push(min);
      }

      // Get the max value of y from the set0 array
      const allYValues = set0.flatMap((item) => item.y);
      const maxY = Math.max(...allYValues);
      const minY = Math.min(...allYValues);

      const dynamicMaxY = Math.max(
        maxY,
        device?.triggerHigh || device?.lowLevelPercentage || 0
      );
      const dynamicMinY = Math.min(minY, device?.triggerLow || 0);

      //   Update the yaxis.max in chart options
      const updatedOptions = {
        ...chartOptions,
        xaxis: {
          ...chartOptions.xaxis,
          labels: {
            ...chartOptions.xaxis.labels,
            formatter: (value: string) =>
              formatDate(value, filter.currentBucket),
          },
        },
        yaxis: {
          ...chartOptions.yaxis,
          labels: {
            ...chartOptions.yaxis.labels,
            formatter: (value: number) =>
              `${value.toFixed(2)} ${t("device.units", {
                context: config.units,
              })}`,
          },
          max:
            dynamicMaxY < 0
              ? dynamicMaxY * 0.9
              : dynamicMaxY === 0
              ? dynamicMaxY + 1
              : dynamicMaxY * 1.1,
          min:
            dynamicMinY > 0
              ? dynamicMinY * 0.9
              : dynamicMinY === 0
              ? dynamicMinY - 1
              : dynamicMinY * 1.1,
        },
        tooltip: {
          custom: (value: any) => {
            const seriesValue =
              value.w.globals.initialSeries[0].data[value.dataPointIndex].y;
            const xLabel = value.w.globals.labels[value.dataPointIndex];
            console.log(seriesValue);
            return `
                <div class="custom-tooltip">
                  <div>${formatDate(xLabel, filter.currentBucket)}</div>
                  <div>${t("device.tooltip.description.one", {
                    context: config.units,
                  })} ${seriesValue[1].toFixed(2)} ${t("device.units", {
              context: config.units,
            })}</div>
              <div>${t("device.tooltip.description.two", {
                context: config.units,
              })} ${((seriesValue[1] + seriesValue[0]) / 2).toFixed(2)} ${t(
              "device.units",
              {
                context: config.units,
              }
            )}</div>
              <div>${t("device.tooltip.description.three", {
                context: config.units,
              })} ${seriesValue[0].toFixed(2)} ${t("device.units", {
              context: config.units,
            })}</div>
                </div>`;
          },
        },
        annotations: {
          ...chartOptions.annotations,
          yaxis: [
            ...(device?.triggerHigh !== undefined ||
            device?.lowLevelPercentage !== undefined
              ? [
                  {
                    y: device.triggerHigh,
                    yAxisIndex: 0,
                    label: {
                      text: `${t("trigger.high", { context: config.units })} ${
                        device.triggerHigh || device.lowLevelPercentage
                      } ${t("device.units", {
                        context: config.units,
                      })}`,
                      offsetX: 0,
                      offsetY: -5,
                      borderColor: "#f87171",
                      style: {
                        color: "#fff",
                        background: "#f87171",
                      },
                    },
                  },
                ]
              : []),
            ...(device?.triggerLow !== undefined
              ? [
                  {
                    y: device.triggerLow,
                    yAxisIndex: 0,
                    label: {
                      text: `${t("trigger.low")} ${device.triggerLow} ${t(
                        "device.units",
                        {
                          context: config.units,
                        }
                      )}`,
                      offsetX: 0,
                      offsetY: -5,
                      borderColor: "#f87171",
                      style: {
                        color: "#fff",
                        background: "#f87171",
                      },
                    },
                  },
                ]
              : []),
          ],
        },
      };

      setChartOptions((prevOptions) => ({
        ...prevOptions,
        ...updatedOptions,
      }));

      const data = [
        {
          name: "set0",
          type: "rangeBar",
          data: set0,
        },
        {
          name: "set1",
          type: "line",
          data: set1,
        },
        {
          name: "set2",
          type: "line",
          data: set2,
        },
      ];

      if (device?.triggerLow) {
        data.push({
          name: "set3",
          type: "line",
          data: set3,
        });
      }

      setData(data);
    }
  };

  useEffect(() => {
    if (device?.snr) {
      fetchData();
    }
  }, [device, date, filter]);

  const getDescription = () => {
    if (filter.calendar === "year") {
      return `${formatDate(startDate, "ddMMMyyyy")} - ${formatDate(
        endDate,
        "ddMMMyyyy"
      )}`;
    } else if (filter.calendar === "decade") {
      return `${formatDate(startDate, "MMMyyyy")} - ${formatDate(
        endDate,
        "MMMyyyy"
      )}`;
    } else if (filter.calendar === "month") {
      return `${formatDate(startDate, "ddMMMyyyy")}`;
    }
    return "";
  };

  return (
    <DeviceCard
      title={t("chart.average.title", {
        context: filter.calendar,
      })}
      subTitle={getDescription()}
      topRight={
        <>
          <Combobox
            id="user-select-calendar"
            placeholder=""
            variant="filter"
            placement="top"
            sizes="sm"
            clearIcon={false}
            value={filter.calendar}
            onChange={(item) => {
              setXFormat(item);
            }}
            items={TimeSeriesChartDropdown}
          />
          <DatePickerField
            id={"1"}
            iconOnly
            sizes={"xs"}
            value={date}
            onChange={(val) => {
              setDate(val); // Set new date and trigger useEffect
            }}
            maxDate={new Date()}
            maxDetail={filter.calendar}
          />
        </>
      }
    >
      <div className="h-full min-h-64 w-full overflow-x-auto">
        {loading ? (
          <div className="mt-12 flex items-center justify-center bg-opacity-50">
            <PropagateLoader loading={loading} color="var(--color-500)" />
          </div>
        ) : data.length > 0 && data[0].data.length > 0 ? (
          <>
            <div className="ml-4 flex items-center gap-2 text-txt-sm text-tertiary">
              <div
                className="h-4 w-4 rounded-sm"
                style={{ backgroundColor: color0 }}
              ></div>
              <div>
                {t("chart.average.timeseries.legend.one", {
                  context: config.units,
                })}
              </div>
              {device?.dailyUsageLimit && filter.calendar === "year" && (
                <>
                  <div
                    className="h-4 w-4 rounded-sm"
                    style={{ backgroundColor: color1 }}
                  ></div>
                  <div>
                    {t("chart.usage.legend.two", {
                      context: config.units,
                    })}
                  </div>
                </>
              )}
            </div>
            <LineChart chartData={data} chartOptions={chartOptions} />
          </>
        ) : (
          <div className="mt-4 flex justify-center">
            <img src={NoDataSvg} alt="Modal content" style={{ width: "12%" }} />
          </div>
        )}
        {/* {data.length > 0 && !loading ? (
          <>
            <div className="ml-4 flex items-center gap-2 text-txt-sm text-tertiary">
              <div
                className="h-4 w-4 rounded-sm"
                style={{ backgroundColor: color0 }}
              ></div>
              <div>
                {t("chart.average.timeseries.legend.one_temperature", {
                  context: config.units,
                })}
              </div>
              {device?.dailyUsageLimit && filter.calendar === "year" && (
                <>
                  <div className={`h-4 w-4 rounded-sm bg-[${color1}]`}></div>
                  <div>
                    {t("chart.usage.legend.two", {
                      context: config.units,
                    })}
                  </div>
                </>
              )}
            </div>
            <LineChart chartData={data} chartOptions={chartOptions} />
          </>
        ) : (
          <div className="mt-12 flex items-center justify-center bg-opacity-50">
            <PropagateLoader loading={loading} color="var(--color-50)" />
          </div>
        )} */}
      </div>
    </DeviceCard>
  );
};

export default Timeseries;
