import { InputHTMLAttributes } from "react";
import ReactJson from "react-json-view";

export function JsonViewer({
  data = { data: "No data to display" },
  onEdit,
  onAdd,
  ...props
}: {
  data?: any;
  onEdit?: (e: any) => void;
  onAdd?: (e: any) => void;
} & Omit<InputHTMLAttributes<HTMLInputElement>, "onChange">) {
  return (
    <div className="z-0 h-full w-full">
      <ReactJson
        src={data}
        style={{ minHeight: "100%", width: "100%" }}
        iconStyle="circle"
        enableClipboard={true}
        onEdit={onEdit ? onEdit : false}
        displayDataTypes={false}
        displayObjectSize={false}
        onAdd={onAdd ? onAdd : false}
        indentWidth={4}
        collapsed={false}
        collapseStringsAfterLength={25}
      />
    </div>
  );
}
