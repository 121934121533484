import { deviceGet } from "api/device";
import { Device } from "api/types/device";
import {
  deviceConfiguration,
  DeviceConfiguration,
} from "models/devices/configuration";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { setLoading } from "store/appSlice";
import Installer from "./components/installer";
import Raws from "./components/raws";
import DeviceRaw from "./components/device";
import Flattend from "./components/flattend";
import { JOB_STATUS } from "widgets/dialogs/jobStatus";

const DevTools = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  // Extract the filter parameter
  const snr = searchParams.get("snr");
  const job = searchParams.get("job");
  const state = searchParams.get("status");

  const [device, setDevice] = useState<Device>(null);
  const [config, setConfig] = useState<DeviceConfiguration>(null);

  const fetchData = async () => {
    dispatch(setLoading(true));
    const { data, status, isLoading, error } = await deviceGet(snr);
    dispatch(setLoading(isLoading));
    if (!error) {
      const config = deviceConfiguration.getDeviceByTag(data.model);
      setConfig(config);
      setDevice(data);
    }
  };

  useEffect(() => {
    if (snr) {
      fetchData();
    }
  }, [snr]);

  return (
    <>
      {config && (
        <div className={`px-2  md:px-4`}>
          <div className="flex h-contentFitHeight flex-col gap-4   lg:flex-row">
            {/* Group for CARD 1, 2, and 3 taking 2/3 of the row */}

            <div className="flex h-contentFitHeight1 flex-col gap-4 lg:w-1/3">
              <Installer
                device={device}
                config={config}
                job={job}
                status={state as JOB_STATUS}
              />
              <Flattend device={device} config={config} />
            </div>
            {/* CARD 4 taking 1/3 of the row */}
            <div className=" lg:w-1/3">
              <DeviceRaw device={device} config={config} />
            </div>
            {/* CARD 4 taking 1/3 of the row */}
            <div className=" lg:w-1/3">
              <Raws device={device} config={config} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DevTools;
