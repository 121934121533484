export const formatDate = (
  date: Date | string,
  format:
    | "short"
    | "long"
    | "time"
    | "lastcomms"
    | "default"
    | "day"
    | "hour"
    | "month"
    | "year"
    | "ddMMMyyyy"
    | "MMMyyyy" = "default"
): string => {
  if (!date) return "";

  // If the date is a string, parse it into a Date object
  const dateObj = typeof date === "string" ? new Date(date) : date;

  const options: Intl.DateTimeFormatOptions = {};

  switch (format) {
    case "short":
      options.year = "numeric";
      options.month = "short";
      options.day = "numeric";
      break;
    case "long":
      options.weekday = "long";
      options.year = "numeric";
      options.month = "long";
      options.day = "numeric";
      break;
    case "time":
      options.hour = "2-digit";
      options.minute = "2-digit";
      options.second = "2-digit";
      break;
    case "lastcomms":
      options.year = "numeric";
      options.month = "numeric";
      options.day = "numeric";
      options.hour = "2-digit";
      options.minute = "2-digit";
      break;
    case "hour":
      options.hour = "2-digit";
      break;
    case "day":
      options.day = "numeric";
      break;
    case "month":
      options.month = "short";
      break;
    case "year":
      options.year = "numeric";
      break;
    case "ddMMMyyyy":
      return `${dateObj.getDate()} ${dateObj.toLocaleString("en-EU", {
        month: "short",
      })} ${dateObj.getFullYear()}`;
    case "MMMyyyy":
      return `${dateObj.toLocaleString("en-EU", {
        month: "short",
      })} ${dateObj.getFullYear()}`;

    default:
      options.day = "2-digit";
      options.month = "2-digit";
      options.year = "numeric";
  }

  return new Intl.DateTimeFormat("en-ZA", options).format(dateObj);
};

export const formatDateToZ = (
  date: Date,
  endOfDay = false,
  granularity: "day" | "month" | "year" = "day"
) => {
  const newDate = new Date(date);

  switch (granularity) {
    case "day":
      if (endOfDay) {
        newDate.setHours(23, 59, 59, 999); // End of day
      } else {
        newDate.setHours(0, 0, 0, 0); // Start of day
      }
      break;

    case "month":
      if (endOfDay) {
        // End of month
        newDate.setMonth(newDate.getMonth() + 1); // Move to the first of the next month
        newDate.setDate(0); // Set to the last day of the previous month
        newDate.setHours(23, 59, 59, 999); // End of day
      } else {
        // Start of month
        newDate.setDate(1); // Set to the first day of the month
        newDate.setHours(0, 0, 0, 0); // Start of day
      }
      break;

    case "year":
      if (endOfDay) {
        // End of year
        newDate.setMonth(11, 31); // Set to December 31st
        newDate.setHours(23, 59, 59, 999); // End of day
      } else {
        // Start of year
        newDate.setMonth(0, 1); // Set to January 1st
        newDate.setHours(0, 0, 0, 0); // Start of day
      }
      break;

    default:
      throw new Error("Invalid granularity. Use 'day', 'month', or 'year'.");
  }

  return newDate.toISOString();
};
