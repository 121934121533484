// Define the enum for sections
export enum Section {
  USER_COUNT = "userCount",
  SITE_COUNT = "siteCount",
  DEVICE_COUNT = "deviceCount",
  DEVICE_LOCATIONS = "deviceLocations",
  DEVICE_MODEL_BREAKDOWN = "deviceModelBreakdown",
  DEVICES_DOWN = "devicesDown",
  ZERO_COUNT = "zeroCount",
  LEAKING = "leaking",
  WEAK_SIGNAL = "weakSignal",
  LOW_BATTERY = "lowBattery",
  WATER_BREAKDOWN = "waterBreakdown",
  LEVEL_BREAKDOWN = "levelBreakdown",
  TEMPERATURE_BREAKDOWN = "temperatureBreakdown",
  ELECTRICITY_BREAKDOWN = "electricityBreakdown",
}

// Update the DashboardReqBody type to use the enum
export type DashboardReqBody = {
  sections: Section[];
};

export type DeviceLocation = {
  snr: string;
  location: string;
};

export type OverviewDashboardMetrics = {
  userCount: number;
  siteCount: number;
  deviceCount: number;
  deviceLocations: DeviceLocation[];
  deviceModelBreakdown: {};
};
