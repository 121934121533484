import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RateStructure } from "api/types/rates";
import { SiteListRespData } from "api/types/site";

export type AppState = {
  loading: boolean;
  welcome: boolean;
  sites: any[];
  rateStructures: RateStructure[];
  issuesList: string[];
};
const initialState: AppState = {
  loading: false,
  welcome: false,
  sites: [],
  rateStructures: [],
  issuesList: [],
};

// This slice contains data related to the state of the app
const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setLoading: (state: any, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setWelcome: (state: any, action: PayloadAction<boolean>) => {
      state.welcome = action.payload;
    },
    setSiteList: (state, action: PayloadAction<SiteListRespData[]>) => {
      state.sites = action.payload;
    },
    setRateStructures: (state, action: PayloadAction<RateStructure[]>) => {
      state.rateStructures = action.payload;
    },
    setIssuesList: (state, action: PayloadAction<string[]>) => {
      state.issuesList = action.payload;
    },
  },
});

export const {
  setLoading,
  setSiteList,
  setRateStructures,
  setWelcome,
  setIssuesList,
} = appSlice.actions;
export default appSlice.reducer;
