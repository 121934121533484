import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalCloseButton,
} from "@chakra-ui/modal";
import { PortalManager } from "@chakra-ui/portal";
import { ratesStructureAdd } from "api/rates";
import { createSite } from "api/site";
import { RatesStructureAddBody } from "api/types/rates";
import { SiteCreateBody } from "api/types/site";
import { notifySimple } from "providers/toast";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setLoading } from "store/appSlice";
import { formatDateToZ } from "util/datelib";
import RateStructureForm, {
  RateStructureFormType,
} from "widgets/forms/rates/ratesStructure";

const RateStructureDialog = (props: {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Function for linking an user
  const doAddRateStructure = async (formData: RateStructureFormType) => {
    dispatch(setLoading(true));
    const body: RatesStructureAddBody = {
      name: formData.name,
      publicFlag: formData.publicFlag,
      billingDate: formData.billingDate,
      vatInclusive: formData.vatInclusive,
      startDate: formatDateToZ(formData.startDate),
      endDate: formatDateToZ(formData.endDate),
    };

    const { status } = await ratesStructureAdd(body);
    dispatch(setLoading(false));
    if (status === 200) {
      props.onSuccess();
      notifySimple("Created Site", "success");
    } else {
      notifySimple("Failed to Create Site", "error");
    }
  };

  return (
    <PortalManager zIndex={2000}>
      <Modal isCentered isOpen={props.isOpen} onClose={props.onClose}>
        <ModalOverlay className="bg-[#000] !opacity-30" />
        <ModalContent className="top-[22vh] !m-auto !w-max min-w-96 !max-w-[40%] rounded-md bg-white md:top-[12vh]">
          <ModalHeader className="heading-md px-6 py-4 text-primary dark:text-darkPrimary">
            {"Add Rate Structure"}
          </ModalHeader>
          <ModalCloseButton className="absolute right-[20px] top-[20px] z-10 text-primary dark:text-darkPrimary" />
          <ModalBody className="px-6 py-2 text-txt-md text-tertiary">
            <RateStructureForm
              submit={(data) => {
                doAddRateStructure(data);
              }}
              onClose={props.onClose}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </PortalManager>
  );
};

export default RateStructureDialog;
