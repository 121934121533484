import { CellContext } from "@tanstack/react-table";
import Dropdown from "components/dropdown";
import { useTranslation } from "react-i18next";
import { MdMoreHoriz } from "react-icons/md";
import DropdownButton from "./shared/buttons/dropdown";

export class DropDownItem {
  //If the label is '-', then a horizontal line will be displayed
  public label: string;
  public active: boolean;
  public onSelected?: (info: CellContext<any, string>) => void;
  permissionRole?: string;
}

const MoreAction = (props: {
  title: string;
  info: CellContext<any, string>;
  items: DropDownItem[];
  extra?: string;
  onClick?: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <div className={`${props.extra}`}>
      <Dropdown
        button={
          <button
            className={`rounded-xl px-1 py-1 transition duration-200 hover:bg-gray-50 active:bg-gray-300 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30`}
            onClick={props.onClick}
          >
            <MdMoreHoriz className="text-gray-700" />
          </button>
        }
        children={
          <div className="flex h-max w-56 flex-col justify-start rounded-[10px] border border-gray-100 bg-white bg-cover bg-no-repeat p-4 shadow-[0_20px_25px_-5px] shadow-shadow-500 dark:border-none dark:!bg-navy-700 dark:text-white dark:shadow-none">
            <div className="ml-0">
              <div className="flex items-center gap-2">
                <p className="heading-sm text-primary">{props.title}</p>
              </div>
            </div>
            <div className="mt-3 h-px w-full bg-gray-200 dark:bg-white/20" />

            <div className="mt-3 flex flex-col items-start">
              {props.items.map(
                (item, index) =>
                  item.active &&
                  (item.label != "-" ? (
                    <DropdownButton
                      key={`action-${item.label}-${index}`}
                      text={item.label}
                      onClick={() => item.onSelected(props.info)}
                      extra={"h-8 px-1 text-txt-md"}
                      permissionRole={item.permissionRole}
                    />
                  ) : (
                    <div className="my-1 h-px w-full bg-gray-200 dark:bg-white/20" />
                  ))
              )}
            </div>
          </div>
        }
        classNames={"-left-[235px] w-max"}
      />
    </div>
  );
};

export default MoreAction;
