interface DeviceHealth {
  battery?: number[];
  signal?: number[];
  credit?: number[];
}

export interface DeviceConfiguration {
  model: string;
  units: string;
  type: string;
  raw: string;
  sensors?: string[];
  health: DeviceHealth;
  canRoute: boolean;
}

export class Configuration {
  //#region : Constant objects for devices

  // Smart water meter
  private readonly LORA_IRRIGATOR: DeviceConfiguration = {
    model: "lora.irrigator",
    units: "water",
    type: "usage",
    raw: "irrigator",
    canRoute: true,
    health: {
      battery: [3.6, 3.3],
      signal: [-122, -135],
    },
  };

  // Smart water meter
  private readonly TAG_RHW: DeviceConfiguration = {
    model: "tag.rhw",
    units: "water",
    type: "usage",
    raw: "water-meter",
    canRoute: true,
    health: {
      battery: [3.6, 3.3],
      signal: [-122, -135],
    },
  };

  // Smart water meter with Valve/Timers
  private readonly TAG_WMD: DeviceConfiguration = {
    model: "tag.wmd",
    units: "water",
    type: "usage",
    raw: "water-meter",
    canRoute: true,
    health: {
      battery: [3.6, 3.3],
      signal: [-122, -135],
    },
  };

  // Smart water meter with Valve/Timers (LoRaWan)
  private readonly LORA_WMD: DeviceConfiguration = {
    model: "lora.wmd",
    units: "water",
    type: "usage",
    raw: "water-meter",
    canRoute: true,
    health: {
      battery: [3.6, 3.3],
      signal: [-122, -135],
    },
  };

  // Smart water meter with Valve/Timers (LoRaWan)
  private readonly BULK_WMD: DeviceConfiguration = {
    model: "bulk.wmd",
    units: "water",
    type: "usage",
    raw: "water-meter",
    canRoute: true,
    health: {
      battery: [3.6, 3.3],
      signal: [-122, -135],
    },
  };

  // Smart water meter with Valve
  private readonly TAG_H2O: DeviceConfiguration = {
    model: "tag.h2o",
    units: "water",
    type: "usage",
    raw: "water-meter",
    canRoute: true,
    health: {
      battery: [3.6, 3.3],
      signal: [-122, -135],
    },
  };

  // Smart electrical meter
  private readonly TAG_RHE: DeviceConfiguration = {
    model: "tag.rhe",
    units: "electricity",
    type: "usage",
    raw: "meterreading",
    canRoute: true,
    health: {
      credit: [10],
    },
  };

  // Optical sensor
  private readonly TAG_OPT: DeviceConfiguration = {
    model: "tag.opt",
    units: "electricity",
    type: "usage-multi",
    raw: "forwardticks",
    sensors: ["forwardticks", "backwardticks"],
    canRoute: true,
    health: {
      battery: [3.6, 3.3],
      signal: [-122, -135],
    },
  };

  private readonly LORA_ABBELEC: DeviceConfiguration = {
    model: "lora.abbelec",
    units: "electricity",
    type: "usage",
    raw: "electrical-meter",
    canRoute: true,
    health: {
      battery: [3.6, 3.3],
      signal: [-122, -135],
    },
  };

  // Temperature sensor
  private readonly TAG_TMP: DeviceConfiguration = {
    model: "tag.tmp",
    units: "temperature",
    type: "average",
    raw: "temp-meter",
    canRoute: true,
    health: {
      battery: [3.6, 3.3],
      signal: [-122, -135],
    },
  };

  private readonly LORA_NANOTAG: DeviceConfiguration = {
    model: "lora.nanotag",
    units: "temperature",
    type: "average",
    raw: "temp-meter",
    canRoute: true,
    health: {
      battery: [3.6, 3.3],
      signal: [-122, -135],
    },
  };

  private readonly LORA_TMP: DeviceConfiguration = {
    model: "lora.tmp",
    units: "temperature",
    type: "average",
    raw: "temp-meter",
    canRoute: true,
    health: {
      battery: [3.6, 3.3],
      signal: [-122, -135],
    },
  };

  private readonly TAG_TMO: DeviceConfiguration = {
    model: "tag.tmo",
    units: "temperature",
    type: "average",
    raw: "temp-meter",
    canRoute: true,
    health: {
      battery: [3.6, 3.3],
      signal: [-122, -135],
    },
  };

  private readonly TAG_DRY: DeviceConfiguration = {
    model: "tag.dry",
    units: "drycontact",
    type: "",
    raw: "drycontact",
    canRoute: false,
    health: {
      battery: [3.6, 3.3],
      signal: [-122, -135],
    },
  };

  // Temperature sensor
  private readonly SHV_TMP: DeviceConfiguration = {
    model: "tag.tmp",
    units: "temperature",
    type: "average",
    raw: "temp-meter",
    canRoute: true,
    health: {
      battery: [3.6, 3.3],
      signal: [-122, -135],
    },
  };

  // Level meter (sensor_'measure': 'type')
  private readonly TAG_LVL: any = {
    model: "tag.lvl",
    units: "level",
    type: "average",
    raw: "level",
    canRoute: true,
    health: {
      battery: [3.6, 3.3],
      signal: [-122, -135],
    },
  };

  // QR maunual water
  private readonly QRW_MAN: any = {
    model: "qrw.man",
    sensor_raw: "water-meter",
    event_cause: "newreading",
    units: "",
    type: "qr_manual",
    health: {
      photo: {
        show: true,
        allowed_users: [],
        cause: "newreading",
        type: "photo",
        levels: [],
      },
    },
    actions: ["info", "qrcode"],
  };

  // QR maunual electricity
  private readonly QRE_MAN: any = {
    model: "qre.man",
    sensor_raw: "water-meter",
    event_cause: "newreading",
    units: "",
    type: "qr_manual",
    health: {
      photo: {
        show: true,
        allowed_users: [],
        cause: "newreading",
        type: "photo",
        levels: [],
      },
    },
    actions: ["info", "qrcode", "tickethistory"],
  };

  // QR prepaid water
  private readonly QRW_PRE: any = {
    model: "qrw.pre",
    units: "",
    type: "qr_prepaid",
    health: {
      photo: {
        show: true,
        allowed_users: [],
        key: "Photo 2",
        type: "photo",
        levels: [],
      },
    },
    actions: ["info", "qrcode", "tickethistory"],
  };

  // QR prepaid electricity
  private readonly QRE_PRE: any = {
    model: "qre.pre",
    units: "",
    type: "qr_prepaid",
    health: {
      photo: {
        show: true,
        allowed_users: [],
        key: "Photo 2",
        type: "photo",
        levels: [],
      },
    },
    actions: ["info", "qrcode", "tickethistory"],
  };

  // QR prepaid gas
  private readonly QRG_PRE: any = {
    model: "qrg.pre",
    units: "",
    type: "qr_prepaid",
    health: {
      photo: {
        show: true,
        allowed_users: [],
        key: "Photo 2",
        type: "photo",
        levels: [],
      },
    },
    actions: ["info", "qrcode"],
  };

  // Pool transport water meter
  private readonly ROM_PTW: any = {
    model: "rom.ptw",
    sensor_voltage3: "3v battery",
    sensor_voltage6: "6v battery",
    sensor_input: "solenoid",
    sensor_raw: "kl",
    sensor_pulse: "pulse",
    units: "kl",
    type: "pool-water",
    health: {
      tamper: {
        probe_tamper: {
          show: true,
          allowed_users: [],
          check: "probetamper",
          type: "pool-tamper",
        },
        ir_tamper: {
          show: true,
          allowed_users: [],
          check: "irtamper",
          type: "pool-tamper",
        },
        tilt_tamper: {
          show: true,
          allowed_users: [],
          check: "tilttamper",
          type: "pool-tamper",
        },
      },
      solenoid: {
        show: true,
        allowed_users: [],
        measure: "input",
        type: "solenoid",
      },
      battery: {
        battery3: {
          show: true,
          allowed_users: [],
          measure: "voltage",
          type: "3v battery",
          levels: [3.6, 3.5, 3.4, 3.3],
        },
        battery6: {
          show: true,
          allowed_users: [],
          measure: "voltage",
          type: "6v battery",
          levels: [3.6, 3.5, 3.4, 3.3],
        },
      },
    },
    status: {
      probe_tamper: {
        show: true,
        allowed_users: [],
        check: "probetamper",
      },
      ir_tamper: {
        show: true,
        allowed_users: [],
        check: "irtamper",
      },
      tilt_tamper: {
        show: true,
        allowed_users: [],
        check: "tilttamper",
      },
      offset: {
        show: true,
        allowed_users: [],
        check: "offset",
      },
      pulse_kl: {
        show: true,
        allowed_users: [],
        check: "pulseperkl",
      },
    },
    actions: ["info", "qrcode"],
  };

  // Pool transport electrical meter
  private readonly ROM_PTE: any = {
    model: "rom.pte",
    sensor_powerI: "energy-import",
    sensor_raw: "energy-import",
    sensor_powerE: "energy-export",
    sensor_kva1: "line 1",
    sensor_kva2: "line 2",
    sensor_kva3: "line 3",
    units: "kWh",
    type: "pool-electricity",
    health: {
      contactor_state: {
        show: true,
        allowed_users: [],
        check: "contactor state",
        type: "contactor",
      },
      toggle: {
        show: true,
        allowed_users: [],
        check: "haschanged",
        type: "toggle",
      },
    },
    status: {
      contactor_state: {
        show: true,
        allowed_users: [],
        check: "contactor state",
      },
    },
    actions: ["toggle", "info", "qrcode"],
  };

  // Pool transport electrical meter
  private readonly ROM_RHE: any = {
    model: "rom.rhe",
    sensor_powerI: "energy-import",
    sensor_raw: "energy-import",
    sensor_powerE: "energy-export",
    sensor_kva1: "line 1",
    sensor_kva2: "line 2",
    sensor_kva3: "line 3",
    units: "kWh",
    type: "pool-electricity",
    health: {
      contactor_state: {
        show: true,
        allowed_users: [],
        check: "contactor state",
        type: "contactor",
      },
    },
    status: {
      contactor_state: {
        show: true,
        allowed_users: [],
        check: "contactor state",
      },
    },
    actions: ["info", "qrcode"],
  };

  //#endregion

  private readonly devices: { [key: string]: DeviceConfiguration } = {
    "tag.lvl": this.TAG_LVL,
    "tag.tmp": this.TAG_TMP,
    "tag.tmo": this.TAG_TMO,
    "tag.rhw": this.TAG_RHW,
    "tag.wmd": this.TAG_WMD,
    "tag.h2o": this.TAG_H2O,
    "tag.rhe": this.TAG_RHE,
    "tag.opt": this.TAG_OPT,
    "qrw.man": this.QRW_MAN,
    "qre.man": this.QRE_MAN,
    "qrw.pre": this.QRW_PRE,
    "qre.pre": this.QRE_PRE,
    "qrg.pre": this.QRG_PRE,
    "rom.ptw": this.ROM_PTW,
    "rom.pte": this.ROM_PTE,
    "rom.rhe": this.ROM_RHE,
    "shv.tmp": this.SHV_TMP,
    "tag.dry": this.TAG_DRY,
    "lora.wmd": this.LORA_WMD,
    "lora.nanotag": this.LORA_NANOTAG,
    "lora.tmp": this.LORA_TMP,
    "lora.abbelec": this.LORA_ABBELEC,
    "lora.irrigator": this.LORA_IRRIGATOR,
    "bulk.wmd": this.BULK_WMD,
  };

  public getDeviceByTag(tag: string): DeviceConfiguration | undefined {
    return this.devices[tag];
  }

  public canDeviceRoute(tag: string): boolean {
    const deviceConfig = this.getDeviceByTag(tag);
    return deviceConfig?.canRoute ?? false;
  }
}

export var deviceConfiguration = new Configuration();
