import { Device } from "api/types/device";
import { useEffect, useState } from "react";
import PieChart from "components/charts/PieChart";
import { DataReqBody } from "api/types/data";
import { dataAverage, dataUsage } from "api/data";
import Switch from "widgets/shared/inputs/switch";
import DatePickerField from "widgets/shared/inputs/datePicker";
import GenericCard from "widgets/cards/generic";
import Table from "widgets/shared/tables/genericTable";
import defineUsageTablecolumns from "models/tables/usagePage";
import { Updater, SortingState } from "@tanstack/react-table";
import { formatDate, formatDateToZ } from "util/datelib";
import BounceLoader from "react-spinners/ClipLoader";
import { DeviceConfiguration } from "models/devices/configuration";
import { getChartColors, getReadings, processData } from "util/device";
import { t } from "i18next";
import MySvg from "assets/svg/temp-graph.svg";
import DeviceCard from "widgets/cards/device";
import NoDataSvg from "assets/svg/no-data.svg";

interface GeneralProps {
  device?: Device;
  config?: DeviceConfiguration;
}

const DailyAverage: React.FC<GeneralProps> = ({ device, config }) => {
  const { color0, color1 } = getChartColors(config || undefined);
  const columns = defineUsageTablecolumns();
  const [date, setDate] = useState<any>(new Date());
  const [data, setData] = useState<any>();
  const [dataTable, setDataTable] = useState([]);
  const [display, setDisplay] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);

    // Calculate from and to based on the selected date
    const from = formatDateToZ(new Date(date), false); // Start of selected date
    const to = formatDateToZ(new Date(date), true); // End of selected date

    const req: DataReqBody = {
      snr: device?.snr,
      from,
      to,
      bucket: "hour",
      sensors: [config.raw],
    };

    const { data: newData, error: err, isLoading } = await dataAverage(req);
    setLoading(isLoading);

    if (!err) {
      const arr = [];

      for (let index = 0; index < newData.length; index++) {
        const element = newData[index];
        const usage =
          config.units === "level"
            ? processData(device, element[config.raw])
            : element[config.raw];
        if (usage) {
          arr.push({
            timestamp: formatDate(element.timestamp, "lastcomms"),
            raw: `${
              typeof usage === "number" && !isNaN(usage)
                ? usage.toFixed(2)
                : usage
            } ${t("device.units", { context: config.units })}`,
          });
        }
      }

      setDataTable(arr);
      setData(arr[arr.length - 1]);
    }
  };

  useEffect(() => {
    if (device?.snr) {
      fetchData();
    }
  }, [device, date]);

  return (
    <DeviceCard
      title={t("chart.average.daily.title")}
      subTitle={formatDate(date, "ddMMMyyyy")}
      topRight={
        <>
          <Switch
            id={`switch-0`}
            labelKey={t("chart.usage.daily.switch")}
            valueKey={"value"}
            onChange={(val: any) => setDisplay(!display)}
            sizes="md"
          />

          <DatePickerField
            id={"1"}
            sizes="xs"
            iconOnly
            value={date}
            onChange={(val) => {
              setDate(val); // Set new date and trigger useEffect
            }}
            maxDate={new Date()}
          />
        </>
      }
    >
      <div className="flex h-full w-full items-center justify-center">
        {dataTable.length > 0 && !loading ? (
          <>
            {!display && (
              <div className="flex items-center justify-center">
                <svg
                  className="h-80 w-80"
                  viewBox="0 0 200 200"
                  preserveAspectRatio="xMinYMin meet"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <image href={MySvg} width="100%" height="100%" />
                  <foreignObject width="100%" height="100%">
                    <div className="flex h-full w-full flex-col items-center justify-center">
                      <div className="heading-lg text-primary">{`${data.raw}`}</div>
                      <div className="text-txt-xs text-tertiary">
                        {t("chart.pie.lastcomms")}
                      </div>
                      <div className="text-txt-xs text-tertiary">
                        {data.timestamp}
                      </div>
                    </div>
                  </foreignObject>
                </svg>
              </div>
            )}
            {display && (
              <div className="h-80 w-full overflow-auto">
                <Table
                  data={dataTable}
                  columns={columns}
                  pagination={{ pageIndex: 0, pageSize: 100 }}
                  paginationOptions={{
                    onPaginationChange: (pagination) => {},
                    rowCount: 100,
                    pageCount: 1,
                  }}
                  sorting={[{ desc: false, id: "name" }]}
                  onSortingChange={(
                    updaterOrValue: Updater<SortingState>
                  ): void => {
                    throw new Error("Function not implemented.");
                  }}
                />
              </div>
            )}
          </>
        ) : (
          <div className="flex items-center justify-center bg-opacity-50">
            {loading ? (
              <BounceLoader loading={loading} />
            ) : (
              <div className="mt-4 flex justify-center">
                <img
                  src={NoDataSvg}
                  alt="Modal content"
                  style={{ width: "12%" }}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </DeviceCard>
  );
};

export default DailyAverage;
