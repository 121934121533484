export const DATA = {
  DATA: {
    RAWS: "data/raws",
    USAGE: "data/usage",
    AVERAGE: {
      INDEX: "data/average",
      TIME_SERIES: "data/average/timeseries",
    },
  },
};
