export const JobStatusDropdown = [
  {
    label: "COMPLETED",
    value: "completed",
  },
  {
    label: "PENDING",
    value: "pending",
  },
  {
    label: "FAILED",
    value: "failed",
  },
];
