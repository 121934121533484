import { gateway } from "./clients/baseClient";
import { ENDPOINTS } from "./endpoints/index";
import { ResponseData } from "./types/api";
import makeApiRequest from "./clients/apiRequest";
import { versionApiPath } from "./common";
import {
  JobPageReqBody,
  JobPageReqData,
  JobUpdateStateReqBody,
  JobUpdateStateReqData,
} from "./types/jobs";

export async function jobPage(body: JobPageReqBody): Promise<ResponseData> {
  const data: JobPageReqData = {
    endpoint: `${versionApiPath}/${ENDPOINTS.JOB.PAGE}`,
    body: body,
  };
  return await makeApiRequest(
    {
      url: ``,
      method: "post",
      data: data,
    },
    gateway,
    false
  );
}

export async function jobUpdateState(
  body: JobUpdateStateReqBody
): Promise<ResponseData> {
  const data: JobUpdateStateReqData = {
    endpoint: `${versionApiPath}/${ENDPOINTS.JOB.UPDATE.STATE}`,
    body: body,
  };
  return await makeApiRequest(
    {
      url: ``,
      method: "post",
      data: data,
    },
    gateway,
    false
  );
}
