import { RootState } from "store/store";

export const getDeviceListFilters = (state: RootState) =>
  state.page.devicePageFilters;

export const getDashboardOverview = (state: RootState) =>
  state.page.dashboardOverview;

export const getDashboardActions = (state: RootState) =>
  state.page.dashboardActions;
