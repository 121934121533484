import { Device } from "api/types/device";
import LabeledField from "providers/labeledField";
import photo from "assets/svg/empty-photo.svg";

import PhotoButton from "widgets/shared/buttons/photo";
import { t } from "i18next";
import { formatDate } from "util/datelib";
import { DeviceConfiguration } from "models/devices/configuration";
import DeviceCard from "widgets/cards/device";
import { useState } from "react";
import CircleButton from "widgets/shared/buttons/circle-button";
import QrCodeButton from "widgets/shared/buttons/qrcode";
import {
  MdEditDocument,
  MdPerson,
  MdPerson2,
  MdPerson3,
  MdPerson4,
  MdVerified,
} from "react-icons/md";
import JobStatusDialog, { JOB_STATUS } from "widgets/dialogs/jobStatus";
import InstallerDetailsDialog from "widgets/dialogs/installerDetails";
import { useDispatch } from "react-redux";
import { setLoading } from "store/appSlice";
import { userGetInstaller } from "api/user";
import { notifySimple } from "providers/toast";

interface GeneralProps {
  device?: Device;
  job?: string;
  status?: JOB_STATUS;
  config?: DeviceConfiguration;
}

const Installer: React.FC<GeneralProps> = ({ device, config, job, status }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen0, setIsOpen0] = useState(false);
  const dispatch = useDispatch();
  const [installer, setInstaller] = useState<any>({});

  const fetchInstaller = async () => {
    dispatch(setLoading(true));
    const { data, isLoading, error } = await userGetInstaller(
      device?.installerName
    );
    dispatch(setLoading(isLoading));

    if (!error && data) {
      setInstaller(data);
      setIsOpen0(true);
    } else {
      notifySimple("Failed to load installer", "error");
    }

    setLoading(false);
  };

  return (
    <DeviceCard
      title={t("device.installer.details")}
      config={config}
      topRight={
        <div className="-mt-2 flex items-center">
          {job && (
            <CircleButton
              icon={MdVerified}
              size="md"
              onClick={() => {
                setIsOpen(true);
              }}
              extra="-mr-2"
              iconColor={
                status === "completed" ? "text-green-500/80" : "text-red-500/90"
              }
            />
          )}
          <CircleButton
            icon={MdPerson3}
            size="md"
            onClick={() => {
              fetchInstaller();
            }}
            extra="-mr-2"
          />
          <QrCodeButton
            data={device?.snr}
            class={`text-secondary`}
            type="snr"
          />
        </div>
      }
    >
      <div className="grid grid-cols-1 gap-1 md:gap-2">
        <LabeledField
          label={`${t("device.installer.name")}: `}
          id={"name"}
          className="text-txt-sm text-secondary"
        >
          <label
            className={`whitespace-normal break-words text-txt-md text-secondary`}
          >
            {device?.installerName}
          </label>
        </LabeledField>
        <LabeledField
          label={`${t("device.installer.date")}: `}
          id={"date"}
          className="text-txt-sm text-secondary"
        >
          <label
            className={`whitespace-normal break-words text-txt-md text-secondary`}
          >
            {formatDate(device?.installDate, "default")}
          </label>
        </LabeledField>
        <LabeledField
          label={`${t("device.installer.location")}: `}
          id={"location"}
          className="text-txt-sm text-secondary"
        >
          <label
            className={`whitespace-normal break-words text-txt-md text-secondary`}
          >
            {device?.installLocation}
          </label>
        </LabeledField>
        <LabeledField
          label={`${t("device.installer.photos")}: `}
          id={"photos"}
          className="text-txt-sm text-secondary"
        >
          <div className="flex items-center justify-evenly">
            {device?.photo1 && (
              <PhotoButton imageSrc={photo} imageKey={device?.photo1} />
            )}
            {device?.photo2 && (
              <PhotoButton imageSrc={photo} imageKey={device?.photo2} />
            )}
            {device?.photo3 && (
              <PhotoButton imageSrc={photo} imageKey={device?.photo3} />
            )}
          </div>
        </LabeledField>
      </div>
      {isOpen && (
        <JobStatusDialog
          isOpen={isOpen}
          status={status}
          id={job}
          onClose={() => setIsOpen(false)}
          onSuccess={() => {}}
        />
      )}
      {isOpen0 && (
        <InstallerDetailsDialog
          isOpen={isOpen0}
          installer={installer}
          onClose={() => setIsOpen0(false)}
        />
      )}
    </DeviceCard>
  );
};

export default Installer;
