import { t } from "i18next";
import { GoArrowRight } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { getDeviceIcon } from "util/device";
import DashboardCard from "widgets/cards/dashboard";
import CircleButton from "widgets/shared/buttons/circle-button";
import { IconType } from "react-icons";
import { MenuRoutePaths } from "models/constants/routes";
import { useDispatch } from "react-redux";
import { setDevicePageFilters } from "store/page/pageSlice";
import { ModelFilterDropdown } from "models/dropdowns/modelFilter";

interface GeneralProps {
  type?: string;
  count?: number;
}

const Devices: React.FC<GeneralProps> = ({ type, count }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const Icon: IconType | null = type ? getDeviceIcon(type) : null;

  const handleRoute = () => {
    const typeFilter = ModelFilterDropdown.find(
      (filter) => filter.label.toLowerCase() === type?.toLowerCase()
    );
    dispatch(
      setDevicePageFilters({
        paginationState: { pageIndex: 0, pageSize: 10 },
        sortingState: [{ desc: true, id: "name" }],
        totCount: 0,
        pageCount: 0,
        classId: "",
        model: typeFilter?.value || "",
        status: undefined,
        installed: undefined,
      })
    );
    navigate(`/admin${MenuRoutePaths.DEVICES}`);
  };

  return (
    <DashboardCard
      title={t("dashboard.card.device.title", { context: type })}
      bgColor="bg-brand-200"
      textColor="text-primary"
      // extra={"min-h-[200px]"}
      topRight={
        <>
          {type && Icon && (
            <Icon className="heading-xl rounded-lg bg-white p-1 text-primary" />
          )}
        </>
      }
    >
      <div className="flex-grow"></div>
      <div className="heading-4xl text-primary">{count || 0}</div>
      <div className="mb-2 text-txt-xs text-primary">
        {t("dashboard.card.device.connected")}
      </div>
      <div className="absolute bottom-2 right-2">
        <CircleButton
          label={t("dashboard.card.action", { context: "devices" })}
          size="xs"
          variant="ghost"
          color="primary"
          icon={GoArrowRight}
          iconPosition="right"
          onClick={handleRoute}
        />
      </div>
    </DashboardCard>
  );
};

export default Devices;
