export const REPORT = {
  REPORT: {
    RAW: "report/raw",
    AGGREGATE: "report/aggregate",
    DATA: {
      UPTIME: "report/data/uptime",
      USAGE: {
        AGGREGATE: "report/data/usage/aggregate",
        BASIC: "report/data/usage/basic",
      },
    },
  },
};
