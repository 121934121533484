import { t } from "i18next";
import { GoArrowRight } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { getDeviceIcon } from "util/device";
import DashboardCard from "widgets/cards/dashboard";
import CircleButton from "widgets/shared/buttons/circle-button";
import { IconType } from "react-icons";
import { useDispatch } from "react-redux";
import { setIssuesList } from "store/appSlice";

export type DashCounts = {
  count: number;
  devices: string[];
};

interface GeneralProps {
  type?: string;
  metrics?: Record<string, DashCounts>; // Ensure `metrics` is a record of objects
}

const Issues: React.FC<GeneralProps> = ({ type, metrics = {} }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const Icon: IconType | null = type ? getDeviceIcon(type) : null;

  const handleRoute = (devices: string[]) => {
    dispatch(setIssuesList(devices));
    navigate(`/admin/devices/issues?type=${type}`);
  };

  return (
    <DashboardCard
      title={t("dashboard.card.device.title", { context: type })}
      bgColor="bg-brand-200"
      textColor="text-primary"
      extra="heading-lg"
      topRight={
        type && Icon ? (
          <Icon className="heading-xl rounded-lg bg-white p-1 text-primary" />
        ) : null
      }
    >
      <div className="mt-2 flex flex-col gap-4">
        {Object.entries(metrics).map(([key, item]) => (
          <div key={key} className="flex items-baseline justify-between">
            <div>
              <div className="heading-3xl text-primary">{item?.count ?? 0}</div>
              <div className="mb-2 text-txt-xs text-primary">
                {t("dashboard.card.issues", { context: key })}
              </div>
            </div>
            <CircleButton
              label={t("dashboard.card.action", { context: "issues" })}
              size="xs"
              variant="ghost"
              color="primary"
              icon={GoArrowRight}
              iconPosition="right"
              onClick={() => handleRoute(item?.devices)}
            />
          </div>
        ))}
        <div className="flex-grow"></div>
      </div>
    </DashboardCard>
  );
};

export default Issues;
