import { Device } from "api/types/device";
import { useEffect, useState } from "react";
import BounceLoader from "react-spinners/ClipLoader";
import { DeviceConfiguration } from "models/devices/configuration";
import { t } from "i18next";
import DeviceCard from "widgets/cards/device";
import { JsonViewer } from "widgets/shared/jsonViewer";

interface GeneralProps {
  device?: Device;
  config?: DeviceConfiguration;
}

const Flattend: React.FC<GeneralProps> = ({ device, config }) => {
  const [loading, setLoading] = useState(false);
  const [jsonData, setJsonData] = useState({});

  useEffect(() => {
    if (device?.snr) {
      setJsonData(device);
    }
  }, [device]);

  return (
    <DeviceCard
      title={t("card.device.flattened.header")}
      subTitle={t("card.device.flattened.description")}
    >
      <div className="flex w-full items-center justify-center overflow-auto">
        {!loading ? (
          <JsonViewer data={jsonData} />
        ) : (
          <div className="flex items-center justify-center bg-opacity-50">
            <BounceLoader loading={loading} />
          </div>
        )}
      </div>
    </DeviceCard>
  );
};

export default Flattend;
