import { InputHTMLAttributes, useEffect, useState } from "react";
import { t } from "i18next";
import { MdSearch } from "react-icons/md";

export function SearchInput({
  value: initialValue,
  onChange,
  debounce = 2000,
  sizes = "lg",
  rounded = "rounded-2xl",
  extra,
  variant = "solid",
  color = "filter",
  ...props
}: {
  value: any;
  onChange: (value: any) => void;
  debounce?: number;
  sizes?: "lg" | "md" | "sm" | "xs";
  variant?: "solid" | "outline";
  color?: "primary" | "secondary" | "filter";
  extra?: string;
  rounded?: string;
} & Omit<InputHTMLAttributes<HTMLInputElement>, "onChange">) {
  const [value, setValue] = useState<any>(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  let sizeClass = "";
  switch (sizes) {
    case "lg":
      sizeClass = "h-12 px-6 text-txt-lg";
      break;
    case "md":
      sizeClass = "h-10 px-4 text-txt-md";
      break;
    case "sm":
      sizeClass = "h-8 px-2  text-txt-sm";
      break;
    case "xs":
      sizeClass = "h-6 px-2  text-txt-xs";
      break;
  }

  const colorClasses = {
    primary: {
      solid: `text-primary bg-gray-50/10`,
      outline: `border border-brand-100 text-primary`,
    },
    secondary: {
      solid: `text-secondary bg-gray-50/10`,
      outline: `border border-secondary text-secondary`,
    },
    filter: {
      solid: `text-primary bg-gray-50`,
      outline: `border border-gray-50 text-primary`,
    },
  };

  return (
    <div
      className={`flex w-full items-center gap-2 ${rounded} ${sizeClass} ${colorClasses[color][variant]} ${extra}`}
    >
      <MdSearch />
      <input
        {...props}
        placeholder={t("generic.action.search") + "..."}
        className={`block ${colorClasses[color][variant]} w-full border-none bg-transparent hover:border-none hover:outline-none focus:border-none focus:outline-none active:border-none active:outline-none dark:text-darkPrimary ${extra}`}
        value={value ?? ""}
        onChange={(e) => {
          if (e.target.value === "") return setValue("");

          setValue(e.target.value);
        }}
      />
    </div>
  );
}
