import { getDashboard } from "api/dashboard";
import { useAppDispatch } from "util/hooks";
import { setLoading } from "store/appSlice";
import { DashboardReqBody, Section } from "api/types/dashboard";
import { useEffect } from "react";
import { usePermissions } from "hooks/usePermissions";
import Issues from "../components/Issues";
import Generic from "../components/Generic";
import { shallowEqual, useSelector } from "react-redux";
import { getDashboardActions } from "store/page/pageSelectors";
import { setDashboardActions } from "store/page/pageSlice";

const IssuesDashboard = () => {
  const dispatch = useAppDispatch();
  const metrics = useSelector(getDashboardActions, shallowEqual);
  const { canShow } = usePermissions();

  const getDashboardData = async () => {
    const sections: DashboardReqBody = {
      sections: [
        Section.DEVICE_COUNT,
        Section.DEVICES_DOWN,
        Section.ZERO_COUNT,
        Section.LEAKING,
        Section.WEAK_SIGNAL,
        Section.LOW_BATTERY,
        Section.WATER_BREAKDOWN,
        Section.ELECTRICITY_BREAKDOWN,
        Section.TEMPERATURE_BREAKDOWN,
        Section.LEVEL_BREAKDOWN,
      ],
    };
    if (!metrics.deviceCount) dispatch(setLoading(true));
    const { data, isLoading, status } = await getDashboard(sections);
    dispatch(setLoading(isLoading));
    if (status === 200) {
      dispatch(
        setDashboardActions({
          ...metrics,
          deviceCount: data?.deviceCount,
          zeroCount: data?.zeroCount,
          devicesDown: data?.devicesDown,
          leaking: data?.leaking,
          weakSignal: data?.weakSignal,
          water: data?.water,
          electricity: data?.electricity,
          temperature: data?.temperature,
          level: data?.level,
        })
      );
    }
  };

  useEffect(() => {
    getDashboardData();
  }, []);

  return (
    <div className={`mt-6`}>
      <div className={`flex h-full flex-col gap-2`}>
        <div className="grid grid-cols-1 gap-2 lg:grid-cols-5 xl:max-h-52">
          <Generic
            count={metrics?.deviceCount}
            color="50"
            extra="min-h-48"
            context="total"
          />
          <Generic
            metrics={metrics?.zeroCount}
            color="500"
            extra="min-h-48"
            context="zerocount"
          />
          <Generic
            metrics={metrics?.devicesDown}
            color="300"
            textColor="primary"
            extra="min-h-48"
            context="down"
          />
          <Generic
            metrics={metrics?.leaking}
            color="400"
            textColor="primary"
            extra="min-h-48"
            context="leaking"
          />
          <Generic
            metrics={metrics?.weakSignal}
            color="800"
            textColor="primary"
            extra="min-h-48"
            context="weaksignal"
          />
        </div>
        <div className="grid flex-1 grid-cols-1  gap-2 sm:grid-cols-1 lg:grid-cols-4">
          <Issues type="water" metrics={metrics?.water} />
          <Issues type="electricity" metrics={metrics?.electricity} />
          <Issues type="temperature" metrics={metrics?.temperature} />
          <Issues type="level" metrics={metrics?.level} />
        </div>
      </div>
    </div>
  );
};

export default IssuesDashboard;
