import Devices from "../components/Devices";
import MapCard from "../components/MapCard";
import Profile from "../components/Profile";
import { getDashboard } from "api/dashboard";
import { getDashboardDeviceCount, useAppDispatch } from "util/hooks";
import { setLoading } from "store/appSlice";
import { DashboardReqBody, Section } from "api/types/dashboard";
import { useEffect } from "react";
import { ModelFilterDropdown } from "models/dropdowns/modelFilter";
import { usePermissions } from "hooks/usePermissions";
import Generic from "../components/Generic";
import { shallowEqual, useSelector } from "react-redux";
import { getDashboardOverview } from "store/page/pageSelectors";
import { setDashboardOverview } from "store/page/pageSlice";

const OverviewDashboard = () => {
  const dispatch = useAppDispatch();
  const metrics = useSelector(getDashboardOverview, shallowEqual);
  const { canShow } = usePermissions();

  const getDashboardData = async () => {
    const sections: DashboardReqBody = {
      sections: [
        Section.USER_COUNT,
        Section.SITE_COUNT,
        Section.DEVICE_COUNT,
        Section.DEVICE_LOCATIONS,
        Section.DEVICE_MODEL_BREAKDOWN,
      ],
    };
    if (!metrics.data) dispatch(setLoading(true));
    const { data, isLoading, status } = await getDashboard(sections);
    dispatch(setLoading(isLoading));
    if (status === 200) {
      const counts = getDashboardDeviceCount(
        ModelFilterDropdown,
        data?.deviceModelBreakdown || []
      );

      dispatch(
        setDashboardOverview({
          ...metrics,
          data,
          counts,
        })
      );
    } else {
    }
  };

  useEffect(() => {
    getDashboardData();
  }, []);

  return (
    <div className={`mt-6`}>
      <div className={`flex h-full flex-col gap-2`}>
        <div className="grid grid-cols-1 gap-2 lg:grid-cols-3 xl:max-h-52">
          <Profile />
          <Generic
            count={metrics?.data?.userCount}
            color={"500"}
            context={"users"}
          />
          <Generic
            count={metrics?.data?.siteCount}
            color={"300"}
            context={"sites"}
          />
        </div>
        <div className="grid grid-cols-1 gap-2  sm:grid-cols-1 lg:grid-cols-4">
          <Devices type="water" count={metrics?.counts?.water} />
          <Devices type="electricity" count={metrics?.counts?.electricity} />
          <Devices type="temperature" count={metrics?.counts?.temperature} />
          <Devices type="level" count={metrics?.counts?.level} />
        </div>
        <div className={`grid grid-cols-1`}>
          <MapCard markers={metrics?.data?.deviceLocations} />
        </div>
      </div>
    </div>
  );
};

export default OverviewDashboard;
