import { deviceDelete, devicePage } from "api/device";
import { DeviceDeleteBody, DevicePageRespData } from "api/types/device";
import { usePermissions } from "hooks/usePermissions";
import { t } from "i18next";
import { CONST } from "models/constants";
import { deviceConfiguration } from "models/devices/configuration";
import { InstalledDropdown } from "models/dropdowns/installed";
import { ModelFilterDropdown } from "models/dropdowns/modelFilter";
import { StatusDropdown } from "models/dropdowns/status";
import defineDpTablecolumns, {
  DevicePageTableModel,
} from "models/tables/devicePage";
import { notifySimple } from "providers/toast";
import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { accountSiteList } from "store/appSelectors";
import { setLoading } from "store/appSlice";
import { getDeviceListFilters } from "store/page/pageSelectors";
import { setDevicePageFilters } from "store/page/pageSlice";
import TableCard from "widgets/cards/table";
import AddDeviceDialog from "widgets/dialogs/addDevice";
import DataQualityDialog from "widgets/dialogs/dataQuality";
import GenericDialog from "widgets/dialogs/generic";
import GenericButton from "widgets/shared/buttons/generic";
import Combobox from "widgets/shared/inputs/combobox";
import { SearchInput } from "widgets/shared/inputs/search";
import Table from "widgets/shared/tables/genericTable";

const Devices = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const columns = defineDpTablecolumns(
    (info) => {
      const row = info.row.original;
      handleRowClick(row);
    },
    (info) => {
      const row = info.row.original;
      setSelected(row);
      setIsOpen0(true);
    },
    (info) => {
      const row = info.row.original;
      setSelected(row);
      setIsOpen(true);
    }
  );
  const userInfo = useSelector(accountSiteList, shallowEqual);
  const defaultFilters = useSelector(getDeviceListFilters, shallowEqual);
  const { canShow } = usePermissions();

  const [dataSource, setDataSource] = useState<DevicePageRespData[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen0, setIsOpen0] = useState(false);
  const [selected, setSelected] = useState<DevicePageTableModel>();

  const updatePage = () => {
    setDialog(false);
    setIsOpen(false);
    setIsOpen0(false);
    fetchData();
  };

  const fetchData = async () => {
    const {
      paginationState,
      sortingState,
      classId,
      model,
      status,
      installed,
      search,
    } = defaultFilters;
    setIsLoading(true);
    const { data, error, isLoading } = await devicePage({
      search: search,
      pageIndex: paginationState.pageIndex + 1,
      pageSize: paginationState.pageSize,
      sort: sortingState[0].id,
      desc: sortingState[0].desc,
      classId: classId,
      models: model,
      status: status,
      installed: installed,
    });
    setIsLoading(isLoading);
    if (!error) {
      setDataSource(data.devices || []);

      return {
        totCount: data.totalCount,
        pageCount: data.totalPages,
        pageIndex: data.currentPage - 1,
      };
    }

    return null;
  };

  const doDelete = async () => {
    dispatch(setLoading(true));

    const snr: DeviceDeleteBody = {
      snr: selected.snr,
    };
    const { status } = await deviceDelete(snr);
    dispatch(setLoading(false));
    if (status === 200) {
      notifySimple("Device deleted", "success");
      updatePage();
    } else {
      notifySimple("Failed to Delete Device", "error");
    }
  };

  const handleRowClick = (row: DevicePageTableModel) => {
    if (deviceConfiguration.canDeviceRoute(row.model)) {
      navigate(`/admin/devices/device?snr=${row.snr}`);
    } else {
      notifySimple(t("deviceNotSupported"), "error");
    }
  };

  useEffect(() => {
    const loadData = async () => {
      const newPagination = await fetchData();

      if (
        newPagination &&
        (newPagination.pageIndex === defaultFilters.paginationState.pageIndex ||
          newPagination.totCount !== defaultFilters.totCount ||
          newPagination.pageCount !== defaultFilters.pageCount)
      ) {
        dispatch(
          setDevicePageFilters({
            ...defaultFilters,
            paginationState: {
              ...defaultFilters.paginationState,
              pageIndex: newPagination.pageIndex,
            },
            totCount: newPagination.totCount,
            pageCount: newPagination.pageCount,
          })
        );
      }
    };

    if (!isLoading) {
      loadData();
    }
  }, [defaultFilters]);

  return (
    <div>
      {canShow(CONST.ROLE_MANAGER) && (
        <AddDeviceDialog
          isOpen={dialog}
          onClose={() => setDialog(false)}
          onSuccess={updatePage}
        />
      )}
      <GenericDialog
        isOpen={isOpen}
        title={"Warning"}
        onClose={() => setIsOpen(false)}
        onDelete={doDelete}
      >
        <div>{`Are you sure you want to delete this device? SNR: ${selected?.snr}`}</div>
      </GenericDialog>
      <DataQualityDialog
        isOpen={isOpen0}
        device={selected}
        onClose={() => setIsOpen0(false)}
        onSuccess={updatePage}
      />

      <TableCard>
        <Table
          filterbar={
            <div className="flex h-full w-full flex-col gap-y-3">
              <div className="flex justify-end">
                <GenericButton
                  onClick={() => {
                    setDialog(true);
                  }}
                  type="button"
                  text="Add Device"
                  size="md"
                  permissionRole={CONST.ROLE_OWNER}
                />
              </div>
              <div className="grid grid-cols-2 gap-2 lg:grid-cols-5">
                <div className="flex-1 lg:flex-grow-[1]">
                  <Combobox
                    id="device-select-model"
                    placeholder="Select group"
                    variant="filter"
                    value={defaultFilters.model}
                    onChange={(item) => {
                      dispatch(
                        setDevicePageFilters({
                          ...defaultFilters,
                          model: item,
                          paginationState: {
                            ...defaultFilters.paginationState,
                            pageIndex: 0,
                          },
                        })
                      );
                    }}
                    items={ModelFilterDropdown}
                    labelKey="label"
                    valueKey="value"
                    sizes="md"
                    rounded="rounded-xl"
                    disabled={isLoading}
                  />
                </div>
                <div className="flex-1 lg:flex-grow-[1]">
                  <Combobox
                    filter
                    id="device-select-site"
                    placeholder="Select site"
                    variant="filter"
                    value={defaultFilters.classId}
                    onChange={(item) => {
                      dispatch(
                        setDevicePageFilters({
                          ...defaultFilters,
                          classId: item,
                          paginationState: {
                            ...defaultFilters.paginationState,
                            pageIndex: 0,
                          },
                        })
                      );
                    }}
                    items={userInfo}
                    labelKey="name"
                    valueKey="id"
                    sizes="md"
                    rounded="rounded-xl"
                    disabled={isLoading}
                  />
                </div>
                <div className="flex-1 lg:flex-grow-[1]">
                  <Combobox
                    id="device-select-status"
                    placeholder="Select status"
                    variant="filter"
                    value={defaultFilters.status}
                    onChange={(item) => {
                      dispatch(
                        setDevicePageFilters({
                          ...defaultFilters,
                          status: item,
                          paginationState: {
                            ...defaultFilters.paginationState,
                            pageIndex: 0,
                          },
                        })
                      );
                    }}
                    items={StatusDropdown}
                    labelKey="label"
                    valueKey="value"
                    sizes="md"
                    rounded="rounded-xl"
                    disabled={isLoading}
                  />
                </div>
                <div className="flex-1 lg:flex-grow-[1]">
                  <Combobox
                    id="device-select-installed"
                    placeholder="Select installed"
                    variant="filter"
                    value={defaultFilters.installed}
                    onChange={(item) => {
                      dispatch(
                        setDevicePageFilters({
                          ...defaultFilters,
                          installed: item,
                          paginationState: {
                            ...defaultFilters.paginationState,
                            pageIndex: 0,
                          },
                        })
                      );
                    }}
                    items={InstalledDropdown}
                    labelKey="label"
                    valueKey="value"
                    sizes="md"
                    rounded="rounded-xl"
                    permissionRole={CONST.ROLE_MANAGER}
                    disabled={isLoading}
                  />
                </div>
                <div className="flex-1 lg:flex-grow-[1]">
                  <SearchInput
                    onChange={(filters) => {
                      dispatch(
                        setDevicePageFilters({
                          ...defaultFilters,
                          search: filters,
                          paginationState: {
                            ...defaultFilters.paginationState,
                            pageIndex: 0,
                          },
                        })
                      );
                    }}
                    type="text"
                    value={defaultFilters.search}
                    sizes="md"
                    rounded="rounded-xl"
                    disabled={isLoading}
                  />
                </div>
              </div>
            </div>
          }
          loading={isLoading}
          onRowClick={handleRowClick}
          data={dataSource}
          columns={columns}
          pagination={defaultFilters.paginationState}
          paginationOptions={{
            onPaginationChange: (pagination) => {
              const page =
                typeof pagination === "function"
                  ? pagination(defaultFilters.paginationState)
                  : pagination;

              dispatch(
                setDevicePageFilters({
                  ...defaultFilters,
                  paginationState: page,
                })
              );
            },
            rowCount: defaultFilters.totCount,
            pageCount: defaultFilters.pageCount,
          }}
          sorting={defaultFilters.sortingState}
          onSortingChange={(updaterOrValue) => {
            const newSortingState =
              typeof updaterOrValue === "function"
                ? updaterOrValue(defaultFilters.sortingState)
                : updaterOrValue;
            if (newSortingState && newSortingState.length > 0) {
              dispatch(
                setDevicePageFilters({
                  ...defaultFilters,
                  sortingState: newSortingState,
                })
              );
            } else {
              dispatch(
                setDevicePageFilters({
                  ...defaultFilters,
                  sortingState: [{ id: "name", desc: true }],
                })
              );
            }
          }}
        />
      </TableCard>
    </div>
  );
};

export default Devices;
