import { gateway } from "./clients/baseClient";
import { ENDPOINTS } from "./endpoints/index";
import { ResponseData } from "./types/api";
import makeApiRequest from "./clients/apiRequest";
import { versionApiPath } from "./common";
import {
  DeviceAddBody,
  DeviceBulkUploadBody,
  DeviceDeleteBody,
  DeviceDeleteManyBody,
  DeviceListReqBody,
  DevicePageReqBody,
  DevicePageReqData,
  DeviceSetBody,
  DeviceSetIrrigatorValveBody,
} from "./types/device";

export async function devicePage(
  body: DevicePageReqBody
): Promise<ResponseData> {
  const data: DevicePageReqData = {
    endpoint: `${versionApiPath}/${ENDPOINTS.DEVICE.PAGE}`,
    body: body,
  };
  return await makeApiRequest(
    {
      url: ``,
      method: "post",
      data: data,
    },
    gateway,
    false
  );
}

export async function deviceList(
  body: DeviceListReqBody
): Promise<ResponseData> {
  const data = {
    endpoint: `${versionApiPath}/${ENDPOINTS.DEVICE.LIST}`,
    body: body,
  };
  return await makeApiRequest(
    {
      url: ``,
      method: "post",
      data: data,
    },
    gateway,
    false
  );
}

export async function deviceAdd(body: DeviceAddBody): Promise<ResponseData> {
  const data = {
    endpoint: `${versionApiPath}/${ENDPOINTS.DEVICE.ADD}`,
    body: body,
  };
  return await makeApiRequest(
    {
      url: ``,
      method: "post",
      data: data,
    },
    gateway,
    true
  );
}

export async function deviceDelete(
  body: DeviceDeleteBody
): Promise<ResponseData> {
  const data = {
    endpoint: `${versionApiPath}/${ENDPOINTS.DEVICE.DELETE}`,
    body: body,
  };
  return await makeApiRequest(
    {
      url: ``,
      method: "post",
      data: data,
    },
    gateway,
    true
  );
}

export async function deviceDeleteMany(
  body: DeviceDeleteManyBody
): Promise<ResponseData> {
  const data = {
    endpoint: `${versionApiPath}/${ENDPOINTS.DEVICE.DELETE_MANY}`,
    body: body,
  };
  return await makeApiRequest(
    {
      url: ``,
      method: "post",
      data: data,
    },
    gateway,
    true
  );
}

export async function deviceGet(
  snr: string,
  unflattened?: boolean
): Promise<ResponseData> {
  const data = {
    endpoint: `${versionApiPath}/${ENDPOINTS.DEVICE.GET}`,
    body: { snr, unflattened },
  };
  return await makeApiRequest(
    {
      url: ``,
      method: "post",
      data: data,
    },
    gateway,
    true
  );
}

export async function deviceGlobalSearch(snr: string): Promise<ResponseData> {
  const data = {
    endpoint: `${versionApiPath}/${ENDPOINTS.DEVICE.GLOBAL.SEARCH}`,
    body: { snr: snr },
  };
  return await makeApiRequest(
    {
      url: ``,
      method: "post",
      data: data,
    },
    gateway,
    false
  );
}

export async function deviceSet(body: DeviceSetBody): Promise<ResponseData> {
  const data = {
    endpoint: `${versionApiPath}/${ENDPOINTS.DEVICE.SET.INDEX}`,
    body: body,
  };
  return await makeApiRequest(
    {
      url: ``,
      method: "post",
      data: data,
    },
    gateway,
    true
  );
}

export async function deviceSetIrrigatorValves(
  body: DeviceSetIrrigatorValveBody
): Promise<ResponseData> {
  const data = {
    endpoint: `${versionApiPath}/${ENDPOINTS.DEVICE.SET.IRRIGATOR_VALVES}`,
    body: body,
  };
  return await makeApiRequest(
    {
      url: ``,
      method: "post",
      data: data,
    },
    gateway,
    true
  );
}

export async function deviceDataQualitySet(
  snr: string,
  status: string
): Promise<ResponseData> {
  const data = {
    endpoint: `${versionApiPath}/${ENDPOINTS.DEVICE.DATA_QUALITY.SET}`,
    body: { snr: snr, status: status },
  };
  return await makeApiRequest(
    {
      url: ``,
      method: "post",
      data: data,
    },
    gateway,
    true
  );
}

export async function deviceBulkUpload(
  body: DeviceBulkUploadBody
): Promise<ResponseData> {
  const data = {
    endpoint: `${versionApiPath}/${ENDPOINTS.DEVICE.BULK_UPLOAD}`,
    body: body,
  };
  return await makeApiRequest(
    {
      url: ``,
      method: "post",
      data: data,
    },
    gateway,
    true
  );
}

export async function setSpecifics(
  snr: string,
  specifics: any
): Promise<ResponseData> {
  const data: any = {
    endpoint: `${versionApiPath}/${ENDPOINTS.DEVICE.SET.SPECIFICS}`,
    body: { snr, specifics },
  };
  return await makeApiRequest(
    {
      url: ``,
      method: "post",
      data: data,
    },
    gateway,
    true
  );
}
