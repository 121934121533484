import { Device } from "api/types/device";
import { useEffect, useState } from "react";
import BounceLoader from "react-spinners/ClipLoader";
import { DeviceConfiguration } from "models/devices/configuration";
import { t } from "i18next";
import DeviceCard from "widgets/cards/device";
import { JsonViewer } from "widgets/shared/jsonViewer";
import ComboBox from "widgets/shared/inputs/combobox";
import { deviceGet, setSpecifics } from "api/device";
import GenericButton from "widgets/shared/buttons/generic";
import CircleButton from "widgets/shared/buttons/circle-button";
import { MdClose } from "react-icons/md";
import Switch from "widgets/shared/inputs/switch";

interface GeneralProps {
  device?: Device;
  config?: DeviceConfiguration;
}

const DeviceRaw: React.FC<GeneralProps> = ({ device, config }) => {
  const [loading, setLoading] = useState(false);
  const [jsonData, setJsonData] = useState({});
  const [specificsData, setSpecificsData] = useState({});
  const [editedSpecificsData, setEditedSpecificsData] = useState(null);
  const [selectedView, setSelectedView] = useState<"device" | "specifics">(
    "device"
  );

  const fetchData = async () => {
    setLoading(true);
    const { data, isLoading, error } = await deviceGet(device?.snr, true);
    setLoading(isLoading);

    if (!error && data) {
      const { specifics, ...rest } = data; // Extract specifics from response
      setJsonData(rest);
      setSpecificsData(specifics || {});
    }

    setLoading(false);
  };

  useEffect(() => {
    if (device?.snr) {
      fetchData();
    }
  }, [device]);

  const updateSpecifics = async () => {
    setLoading(true);
    const { isLoading, error } = await setSpecifics(
      device?.snr,
      editedSpecificsData?.updated_src
    );
    setLoading(isLoading);

    if (!error) {
      setSpecificsData(editedSpecificsData?.updated_src || {});
      setEditedSpecificsData(null);
    }

    setLoading(false);
  };

  return (
    <DeviceCard
      title={t("card.device.raw.header")}
      subTitle={t("card.device.raw.description")}
      topRight={
        <Switch
          id="device-view-toggle"
          labelKey={t("switch.view.specifics.label")}
          returnBoolean
          onChange={(val: any) => setSelectedView(val ? "specifics" : "device")}
          sizes="md"
          labelPosition="left"
        />
      }
    >
      {editedSpecificsData && (
        <div className="-mt-4 mb-2 flex justify-end gap-2">
          <GenericButton
            text={t("button.action.apply")}
            size="xs"
            rounded="rounded-md"
            onClick={updateSpecifics}
          />
          <CircleButton
            icon={MdClose}
            variant="outline"
            size="xs"
            onClick={() => setEditedSpecificsData(null)}
          />
        </div>
      )}
      <div className="flex w-full items-center justify-center overflow-auto">
        {!loading ? (
          <JsonViewer
            data={selectedView === "device" ? jsonData : specificsData}
            onEdit={
              selectedView === "specifics" ? setEditedSpecificsData : undefined
            }
          />
        ) : (
          <div className="flex items-center justify-center bg-opacity-50">
            <BounceLoader loading={loading} />
          </div>
        )}
      </div>
    </DeviceCard>
  );
};

export default DeviceRaw;
