import { deviceSetIrrigatorValves } from "api/device";

import { notifySimple } from "providers/toast";

import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setLoading } from "store/appSlice";

import GenericDialog from "./generic";
import { formatDate } from "util/datelib";

interface SwitchValveProps {
  isOpen: boolean;
  device: any;
  onClose: () => void;
  onSuccess: (refresh: boolean) => void;
}

const SwitchValveDialog: React.FC<SwitchValveProps> = ({
  isOpen = false,
  device,
  onClose,
  onSuccess,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Function for linking an user
  const handleSwitchValve = async () => {
    if (!device?.valve1Synced) return onSuccess(false);
    dispatch(setLoading(true));

    const { status } = await deviceSetIrrigatorValves({
      snr: device.snr,
      valve1: !valveState,
    });
    dispatch(setLoading(false));
    if (status === 200) {
      onSuccess(true);
      notifySimple("Status updated", "success");
    } else {
      notifySimple("Failed to update", "error");
    }
  };

  const valveState = device?.valveState === "OPEN" ? true : false;
  console.log(formatDate(device?.valve1SetDate, "lastcomms").toString());
  return (
    <>
      <GenericDialog
        isOpen={isOpen}
        title={t("dialog.switch.valve.title")}
        onClose={onClose}
        onOk={handleSwitchValve}
      >
        {device?.valve1Synced ? (
          t("dialog.switch.valve", {
            context: valveState.toString(),
          })
        ) : (
          <>
            <p
              dangerouslySetInnerHTML={{
                __html: t("dialog.switch.valve.pending", {
                  date: formatDate(device?.valve1SetDate, "lastcomms"),
                }),
              }}
            />
          </>
        )}
      </GenericDialog>
    </>
  );
};

export default SwitchValveDialog;
