import * as z from "zod";
import { useForm, useFieldArray } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import InputField from "widgets/shared/inputs/inputField";
import { useTranslation } from "react-i18next";
import LabeledField from "providers/labeledField";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "providers/form";
import GenericButton from "widgets/shared/buttons/generic";
import { useState } from "react";
import { MdCheck, MdClose } from "react-icons/md";
import TooltipHorizon from "components/tooltip";

// Schema definition for the form
const BulkUploadSchema = z.object({
  devices: z
    .array(
      z.object({
        snr: z.string(),
        meternumber: z.string(),
        model: z.string(),
        address: z.string().optional(),
        siteid: z.string().optional(),
        metertype: z.string().optional(),
        metersize: z.string().optional(),
        meterpulse: z.string().optional(),
        status: z.boolean().optional().default(false),
        error: z.string().optional().default(undefined),
      })
    )
    .optional(),
});

export type BulkUploadFormType = z.infer<typeof BulkUploadSchema>;

// Props for the form
type BulkUploadFormProps = {
  submit: (data: BulkUploadFormType) => void;
  clearData: () => void;
  onClose: () => void;
  defaultValues?: BulkUploadFormType;
  values?: BulkUploadFormType;
  loading?: boolean;
  disabled?: boolean;
};

const BulkUploadForm = ({
  submit,
  clearData,
  onClose,
  defaultValues,
  values,
  loading,
  disabled,
}: BulkUploadFormProps) => {
  const { t } = useTranslation();
  const [keys, setKeys] = useState(
    values?.devices.length > 0 ? Object.keys(values.devices[0]) : []
  );

  const form = useForm<BulkUploadFormType>({
    resolver: zodResolver(BulkUploadSchema),
    defaultValues,
    values,
  });

  const {
    control,
    watch,
    formState,
    setValue,
    getValues,
    handleSubmit,
    reset,
  } = form;
  const { errors } = formState;

  const onSubmit = async (formData: BulkUploadFormType) => {
    try {
      await submit(formData);
      reset(formData);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const { fields, append, insert, remove } = useFieldArray({
    control,
    name: "devices", // Handle array for steps
  });

  return (
    <Form {...form}>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-2">
        <div className={`mb-2 grid grid-cols-9 gap-2`}>
          <LabeledField
            key={"header1"}
            className="text-txt-xs"
            label={"SNR"}
            id={"header1"}
            children={""}
          />
          <LabeledField
            key={"header2"}
            className="text-txt-xs"
            label={"METERNUMBER"}
            id={"header2"}
            children={""}
          />
          <LabeledField
            key={"header3"}
            className="text-txt-xs"
            label={"MODEL"}
            id={"header3"}
            children={""}
          />
          <LabeledField
            key={"header4"}
            className="text-txt-xs"
            label={"ADDRESS"}
            id={"header4"}
            children={""}
          />
          <LabeledField
            key={"header5"}
            className="text-txt-xs"
            label={"SITEID"}
            id={"header5"}
            children={""}
          />
          <LabeledField
            key={"header6"}
            className="text-txt-xs"
            label={"METERTYPE"}
            id={"header6"}
            children={""}
          />
          <LabeledField
            key={"header7"}
            className="text-txt-xs"
            label={"METERSIZE"}
            id={"header7"}
            children={""}
          />
          <LabeledField
            key={"header8"}
            className="text-txt-xs"
            label={"METERPULSE"}
            id={"header8"}
            children={""}
          />
          <LabeledField
            key={"header9"}
            className="text-txt-xs"
            label={"STATUS"}
            id={"header9"}
            children={""}
          />
        </div>

        {fields.map((item, index) => (
          <div key={item.snr} className={`grid grid-cols-9 gap-2`}>
            {/* snr */}
            <FormField
              control={control}
              name={`devices.${index}.snr`}
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <InputField
                      sizes="sm"
                      placeholder={""}
                      id={`snr-${index}`}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            {/* meternumber */}
            <FormField
              control={control}
              name={`devices.${index}.meternumber`}
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <InputField
                      sizes="sm"
                      placeholder={""}
                      id={`meternumber-${index}`}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            {/* model */}
            <FormField
              control={control}
              name={`devices.${index}.model`}
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <InputField
                      sizes="sm"
                      placeholder={""}
                      id={`model-${index}`}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            {/* address */}
            <FormField
              control={control}
              name={`devices.${index}.address`}
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <InputField
                      sizes="sm"
                      placeholder={""}
                      id={`address-${index}`}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            {/* siteid */}
            <FormField
              control={control}
              name={`devices.${index}.siteid`}
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <InputField
                      sizes="sm"
                      placeholder={""}
                      id={`siteid-${index}`}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            {/* metertype */}
            <FormField
              control={control}
              name={`devices.${index}.metertype`}
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <InputField
                      sizes="sm"
                      placeholder={""}
                      id={`metertype-${index}`}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            {/* metersize */}
            <FormField
              control={control}
              name={`devices.${index}.metersize`}
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <InputField
                      sizes="sm"
                      placeholder={""}
                      id={`metersize-${index}`}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            {/* meterpulse */}
            <FormField
              control={control}
              name={`devices.${index}.meterpulse`}
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <InputField
                      sizes="sm"
                      placeholder={""}
                      id={`meterpulse-${index}`}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            {/* status */}
            <FormField
              control={control}
              name={`devices.${index}.status`}
              render={({ field }) => (
                <FormItem className="w-10">
                  <FormControl>
                    {field.value === true ? (
                      <MdCheck size={"30"} color="green" />
                    ) : field.value === false ? (
                      <TooltipHorizon
                        extra="custom-class"
                        trigger={<MdClose size={"30"} color="red" />}
                        content={<span>{item.error || "No error"}</span>}
                        placement="left"
                      />
                    ) : null}
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        ))}

        <div className="flex w-full flex-col pt-2">
          <div className="flex justify-end space-x-2">
            <GenericButton
              text={t("button.action.clear")}
              loading={loading || false}
              type="button"
              size="md"
              variant="outline"
              onClick={() => {
                clearData();
              }}
            />
            <GenericButton
              text={t("button.action.upload")}
              loading={loading || false}
              type="submit"
              size="md"
            />
          </div>
        </div>
      </form>
    </Form>
  );
};

export default BulkUploadForm;
