import * as z from "zod";

import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../providers/form";
import InputField from "widgets/shared/inputs/inputField";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import ComboBox from "widgets/shared/inputs/combobox";
import GenericButton from "widgets/shared/buttons/generic";
import { shallowEqual, useSelector } from "react-redux";

import { usePermissions } from "hooks/usePermissions";
// Schema
const UserSchema = z.object({
  email: z
    .string()
    .min(1, { message: i18next.t("zod.error.required") })
    .email({ message: i18next.t("zod.error.email") }),
  role: z.string().min(1, {
    message: i18next.t("zod.error.required"),
  }),
  classes: z.array(z.string()),
});

export type UserFormType = z.infer<typeof UserSchema>;

// Props
type UserFormProps = {
  submit: (data: UserFormType) => void;
  onClose: () => void;
  defaultValues?: UserFormType;
  values?: UserFormType;
  loading?: boolean;
};

const UserForm = ({
  submit,
  onClose,
  defaultValues,
  values,
  loading,
}: UserFormProps) => {
  const form = useForm<UserFormType>({
    resolver: zodResolver(UserSchema),
    defaultValues: {
      email: "",
      role: "",
      classes: [],
    },
    values,
  });

  const { t } = useTranslation();
  const { errors, isDirty } = form.formState;
  const { availableRoles } = usePermissions();

  const onSubmit = async (formData: UserFormType) => {
    try {
      await submit(formData);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const clearErrors = () => {
    form.clearErrors();
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className=" space-y-6">
        <div className="mt-[12px] grid grid-cols-1 gap-3 ">
          <FormField
            control={form.control}
            name="email"
            rules={{ required: true }}
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("form.input.email")}</FormLabel>
                <FormControl>
                  <InputField
                    placeholder={t("form.placeholder.email")}
                    id="email"
                    type="text"
                    state={errors.email ? "error" : undefined}
                    onFocus={clearErrors}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="role"
            rules={{ required: true }}
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("form.input.role")}</FormLabel>
                <FormControl>
                  <ComboBox
                    items={availableRoles()}
                    placeholder={t("form.placeholder.role")}
                    id="role"
                    type="text"
                    state={errors.role ? "error" : undefined}
                    onFocus={clearErrors}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="flex justify-end py-4">
          <div className="flex gap-2">
            <GenericButton
              onClick={onClose}
              text={t("button.action.cancel")}
              size="md"
              variant="ghost"
            />

            <GenericButton
              type="submit"
              text={t("button.action.ok")}
              disabled={!isDirty}
              loading={loading || false}
              size="md"
            />
          </div>
        </div>
      </form>
    </Form>
  );
};

export default UserForm;
