// Props
type DashboardCardProps = {
  title?: string;
  extra?: string;
  textColor?: string;
  bgColor?: string;
  children?: React.ReactNode;
  topRight?: React.ReactNode;
};

const DashboardCard = ({
  title,
  extra,
  textColor = "text-primary",
  bgColor = "white",
  children,
  topRight,
}: DashboardCardProps) => {
  return (
    <div
      className={`!z-5 relative flex w-full flex-col rounded-[15px] p-4 md:p-4 md:px-6 ${bgColor} ${extra}`}
    >
      <div className="mb-2 flex w-full flex-col">
        <div className="flex w-full flex-row justify-between gap-2">
          <div className="flex flex-col truncate">
            <div className={`${textColor} heading-md`}>{title}</div>
          </div>

          <div className="">{topRight}</div>
        </div>
      </div>

      {children}
    </div>
  );
};

export default DashboardCard;
